import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getAllStatement = async (params) => {
  const res = await jwtAxios.get(URL_API.BALANCE.GET_ALL, { params });
  return res;
};

const useGetAllStatement = (params) => {
  return useQuery(
    [URL_API.BALANCE.GET_ALL, params],
    () => getAllStatement(params),
    {
      enabled: !!params.folder_slug && !!params.company_slug,
    }
  );
};

export default useGetAllStatement;
