import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import AppColumnActionOption from "@uni/core/AppColumnActionOption";
import IntlMessages from "@uni/utility/IntlMessages";
import { Tooltip } from "antd";
import useDeleteBulkStatement from "hooks/apis/makingStatement/useDeleteBulkStatement";
import useAppConfirmModal from "hooks/useAppConfirmModal";
import { useIntl } from "react-intl";

const Statement15ColumnActionOption = ({ id, setDetailId }) => {
  const { mutate: mutateDelete } = useDeleteBulkStatement();
  const { contextHolder: contextConfirmDelete, openModal: openConfirmDelete } =
    useAppConfirmModal();
  const { messages } = useIntl();

  const handleDeleteStatement = async () => {
    openConfirmDelete({
      title: messages["app.annex.deleteTaxRisk"]?.toString(),
      okText: messages["common.delete"]?.toString(),
      cancelText: messages["common.cancel"]?.toString(),
      content: messages["app.annex.deleteTaxRisk.content"]?.toString(),
      onOk: () => {
        mutateDelete({ ids: [id] });
      },
    });
  };
  return (
    <>
      <AppColumnActionOption
        items={[
          {
            // scopes: [PERMISSIONS.EDIT_PROJECT],
            label: (
              <Tooltip
                placement="top"
                title={<IntlMessages id="fta.annex.viewDetail" />}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setDetailId(id)}
                >
                  <InfoCircleOutlined
                    style={{ fontSize: "20px", color: "#0A8FDC" }}
                  />
                </div>
              </Tooltip>
            ),
          },
          {
            // scopes: [PERMISSIONS.DELETE_PROJECT],
            label: (
              <Tooltip
                placement="top"
                title={<IntlMessages id="common.delete" />}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleDeleteStatement}
                >
                  <DeleteOutlined
                    style={{ fontSize: "20px", color: "#0A8FDC" }}
                  />
                </div>
              </Tooltip>
            ),
          },
        ]}
      />

      {contextConfirmDelete}
    </>
  );
};

export default Statement15ColumnActionOption;
