import React from "react";
import { PERMISSIONS } from "shared/constants/AppEnums";
const Partner = React.lazy(() => import("./Partner"));
const Commodity = React.lazy(() => import("./Commodity"));
const BomChange = React.lazy(() => import("./BomChange"));
const HsYear = React.lazy(() => import("./HsYear"));
const HsPartner = React.lazy(() => import("./HsPartner"));
const FgDiff = React.lazy(() => import("./FgDiff"));
const ProductionProcess = React.lazy(() => import("./ProductionProcess"));

export const masterConfig = [
  {
    path: "/master/partner",
    element: <Partner />,
    scopes: [PERMISSIONS.VIEW_PARTNER],
  },
  {
    path: "/master/commodity",
    element: <Commodity />,
    scopes: [PERMISSIONS.VIEW_COMMODITY],
  },
  {
    path: "/master/bom-change",
    element: <BomChange />,
    scopes: [PERMISSIONS.VIEW_BOM_CHANGE],
  },
  {
    path: "/master/hs-year",
    element: <HsYear />,
    scopes: [PERMISSIONS.VIEW_HS_DIFFERENCE_YEAR],
  },
  {
    path: "/master/hs-partner",
    element: <HsPartner />,
    scopes: [PERMISSIONS.VIEW_HS_DIFFERENCE_PARTNER],
  },
  {
    path: "/master/FG-different",
    element: <FgDiff />,
    scopes: [PERMISSIONS.VIEW_FG_DIFFERENCE],
  },
  {
    path: "/master/production-process",
    element: <ProductionProcess />,
    scopes: [PERMISSIONS.VIEW_PRODUCTION_PROCESS],
  },
];
