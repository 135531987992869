import React from "react";

const Signin = React.lazy(() => import("./Signin"));
const Signup = React.lazy(() => import("./Signup"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));
const ChangePassword = React.lazy(() => import("./ChangePassword"));
const Verify = React.lazy(() => import("./Verify"));

export const authRouteConfig = [
  {
    path: "/login",
    element: <Signin />,
  },
  {
    path: "/register",
    element: <Signup />,
  },
  {
    path: "/forgot",
    element: <ForgotPassword />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
];
