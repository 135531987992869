import { Button } from "antd";
import React, { useRef } from "react";
import ModifyModal from "../ModifyModal";
import AppCustomModalWrapper from "@uni/core/AppCustomModalWrapper";

const ActionRow = ({ record, data }) => {
  const listModalRef = useRef();
  return (
    <div>
      <Button type="text" onClick={() => listModalRef.current.openModal()}>
        List
      </Button>
      <AppCustomModalWrapper ref={listModalRef}>
        <ModifyModal data={data} record={record} />
      </AppCustomModalWrapper>
    </div>
  );
};

export default ActionRow;
