import AppFormModal from "@uni/core/AppFormModal";
import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";
import IntlMessages from "@uni/utility/IntlMessages";
import React, { forwardRef } from "react";
import useIntl from "react-intl/lib/src/components/useIntl";
import { AppInputType } from "shared/constants/AppEnums";

const ApplyAllModal = forwardRef((_, ref) => {
  const { messages } = useIntl();
  const { setDataRow, dataRow, formData } = useGenerateContext();

  const fieldsConfig = [
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "criteria",
        label: <IntlMessages id="determination.determine.criteria" />,
        labelCol: 24,
      },
      fieldCol: 24,
      inputConfig: {
        placeholder: messages["determination.determine.criteria"],
      },
    },
    {
      type: AppInputType.SELECT,
      fieldCol: 24,
      formItemConfig: {
        name: "insertType",
        label: <IntlMessages id="determination.determine.insertType" />,
        labelCol: { span: 24 },
      },
      inputConfig: {
        placeholder: messages["determination.determine.insertType"],
        options: [
          { value: "formula", label: "Formula" },
          { value: "user_input", label: "User Input" },
        ],
      },
    },
    {
      type: AppInputType.SELECT,
      fieldCol: 24,
      formItemConfig: {
        name: "form_type",
        label: <IntlMessages id="determination.determine.formType" />,
        labelCol: { span: 24 },
      },
      inputConfig: {
        placeholder: messages["determination.determine.formType"],
        options: [
          { value: "CTC", label: "CTC" },
          { value: "RVC", label: "RVC" },
        ],
      },
    },
    ...(formData.select_form === "UKV" || formData.select_form === "EV"
      ? [
          {
            type: AppInputType.SWITCH,
            fieldCol: 24,
            formItemConfig: {
              name: "sedo_option",
              label: <IntlMessages id="determination.determine.sedo" />,
              labelCol: { span: 24 },
            },
          },
        ]
      : []),
  ];
  return (
    <AppFormModal
      width={500}
      fieldsConfig={fieldsConfig}
      ref={ref}
      onFinish={(formData) => {
        const updatedDataRow = dataRow?.map((row) => {
          const updatedRow = { ...row };
          Object.keys(formData).forEach((key) => {
            if (formData[key] !== undefined) {
              updatedRow[key] = formData[key];
            }
          });
          return updatedRow;
        });
        setDataRow(updatedDataRow);
        ref.current?.closeModal();
      }}
      //   title={<IntlMessages id="common.add" />}
    />
  );
});

export default ApplyAllModal;
