import { Col, Form } from "antd";
import { useIntl } from "react-intl";
import { AppRowContainer } from "@uni";
import AppDebouncedInputSearch from "@uni/core/AppDebouncedInputSearch";

const Statement15TableSearch = ({ onValuesChange }) => {
  const { messages } = useIntl();
  return (
    <Form onValuesChange={onValuesChange}>
      <Form.Item name="search" noStyle>
        <AppDebouncedInputSearch
          id="annex"
          placeholder={messages["common.searchHere"]}
        />
      </Form.Item>
    </Form>
  );
};

export default Statement15TableSearch;
