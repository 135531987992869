import { Col, Empty, Select, Spin } from "antd";
import React from "react";
import AppList from "../AppList";
import "./index.style.less";
import AppRowContainer from "../AppRowContainer";
import AppIconButton from "../AppIconButton";
import { AiOutlineClose } from "react-icons/ai";
import { filterByText } from "../../utility/Utils";

const AppSelectDisplayList = ({
  value = [],
  onChange,
  listItemRender = () => {},
  options = [],
  customSelect,
  loading,
}) => {
  return (
    <div className="app-select-display-wrapper">
      {customSelect ? (
        React.cloneElement(customSelect, { value, onChange: onChange })
      ) : (
        <Select
          mode="multiple"
          optionLabelProp="label"
          options={options}
          notFoundContent={loading ? <Spin size="small" /> : <Empty />}
          optionFilterProp="label"
          loading={loading}
          filterOption={(input, option) =>
            filterByText({ value: option?.label, input })
          }
          onChange={onChange}
          value={value}
        />
      )}
      <AppList
        data={value?.reverse()}
        className="list-wrapper"
        renderItem={(item, index) => (
          <AppRowContainer
            style={{
              marginLeft: 0,
              marginRight: 0,
            }}
            gutter={[16, 0]}
            className="item-wrapper"
          >
            <Col
              xs={21}
              style={{
                paddingRight: 0,
                paddingLeft: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              {listItemRender(item, options)}
            </Col>
            <Col
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
              xs={3}
            >
              <AppIconButton
                icon={
                  <AiOutlineClose
                    style={{
                      fontSize: 18,
                    }}
                  />
                }
                onClick={() => {
                  onChange?.(value?.filter((_item, i) => i !== index));
                }}
              />
            </Col>
          </AppRowContainer>
        )}
      />
      {value.length > 0 && (
        <div style={{ color: "darkblue" }}>Total: {value.length}</div>
      )}
    </div>
  );
};

export default AppSelectDisplayList;
