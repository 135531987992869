import React from "react";
import FormA from "./FormA";
import { PERMISSIONS } from "shared/constants/AppEnums";
const Annex = React.lazy(() => import("./Annex"));
const CoList = React.lazy(() => import("./CoList"));
const DeductionHistory = React.lazy(() => import("./DeductionHistory"));

export const ftaConfig = [
  {
    path: "/fta/annex",
    element: <Annex />,
    scopes: [PERMISSIONS.VIEW_ANNEX],
  },
  {
    path: "/fta/co-list",
    element: <CoList />,
    scopes: [PERMISSIONS.VIEW_CO],
  },
  {
    path: "/fta/ecus-co-form",
    element: <FormA />,
    scopes: [PERMISSIONS.VIEW_ECUS],
  },
  {
    path: "/fta/ecus-stock",
    element: <DeductionHistory />,
    scopes: [PERMISSIONS.VIEW_DEDUCTION_HISTORY],
  },
];
