import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import useIntl from "react-intl/lib/src/components/useIntl";
import { useMutation } from "react-query";

const recalculateBangke = (body) =>
  jwtAxios.post(URL_API.BANG_KE.RECALCULATE, body);

const useRecalculate = () => {
  const { messages } = useIntl();

  return useMutation({
    mutationFn: recalculateBangke,
    onSuccess: () => {},
    onError: () => {
      message.error(messages["data.accounting.begin.create.fail"]?.toString());
    },
  });
};

export default useRecalculate;
