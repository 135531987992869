import jwtAxios, { genBodyFormData } from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import {
  convertHiddenList,
  convertShowData,
} from "@uni/utility/ImportConvertData";
import IntlMessages from "@uni/utility/IntlMessages";
import {
  convertDateUni,
  excelIndex,
  isValidFormatCellValue,
} from "@uni/utility/Utils";
import { Button, message, Modal, Spin, Steps } from "antd";
import useDeleteAllFormA from "hooks/apis/coForm/useDeleteAllFormA";
import useGetHeaderFormA from "hooks/apis/coForm/useGetHeaderFormA";
import useImportFormA from "hooks/apis/coForm/useImportFormA";
import useSaveHeaderFormA from "hooks/apis/coForm/useSaveHeaderFormA";
import useImportSelectSheetConfig from "hooks/useImportSelectSheetConfig";
import _debounce from "lodash/debounce";
import { centerItems } from "pages/system/Settings";
import {
  forwardRef,
  lazy,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DOCS_FORM } from "shared/constants/AppConst";
import {
  useDocsFormModalImportActionContext,
  useDocsFormModalImportContext,
} from "./DocsFormModalImportProvider";
import "./index.style.less";
import ButtonPermission from "@uni/core/AppPermission/ButtonPermission";

const ExternalInputModalImport = lazy(() =>
  import("./ExternalInputModalImport")
);
const InternalInputModalImport = lazy(() =>
  import("./InternalInputModalImport")
);
const ResultFormModalImport = lazy(() => import("./ResultFormModalImport"));
const ImportSheet = lazy(() => import("@uni/core/AppImportTable/ImportSheet"));
const SelectSheetTitle = lazy(() =>
  import("@uni/core/AppImportTable/SelectSheetTitle")
);
const SelectHeader = lazy(() =>
  import("@uni/core/AppImportTable/SelectHeader")
);
const MapHeader = lazy(() => import("@uni/core/AppImportTable/MapHeader"));

export const STEPS = {
  EXTERNAL_INPUT: {
    title: "Setting",
    value: 1,
  },
  INTERNAL_INPUT: {
    title: "Input Informations",
    value: 2,
  },
  IMPORT: {
    title: "Import",
    value: 3,
  },
  SELECT_SHEET_TITLE: {
    title: "Select Sheet Title",
    value: 4,
  },
  PICK_HEADER: {
    title: "Pick Header",
    value: 5,
  },
  MAP_HEADER: {
    title: "Map Header",
    value: 6,
  },
  RESULT: {
    title: "Generate",
    value: 7,
  },
};

const DocsFormModalImport = ({ selectedGoodsRow }, ref) => {
  const {
    setExternalData,
    setInternalData,
    setFileName,
    setFile,
    setSheetList,
    setWorkBook,
    setHeadersConvertData,
    setSelectHeader,
    setFormSubmitMapHeaderData,
    setSheetName,
    setMergeCells,
    setSheetData,
    setHeadersData,
    setDataCoList,
    setCurrentStep,
    prevStep,
  } = useDocsFormModalImportActionContext();

  const {
    internalData,
    file,
    sheetData,
    sheetList,
    mergeCells,
    headersData,
    headersConvertData,
    workBook,
    sheetName,
    selectHeader,
    formSubmitMapHeaderData,
    dataCoList,
    currentStep,
  } = useDocsFormModalImportContext();

  const { companyManagement } = useLayoutContext();

  const { mutateAsync: mutateAsyncGetHeader, isLoading: isLoadingGetHeader } =
    useGetHeaderFormA();

  const { mutateAsync: mutateAsyncSaveHeader, isLoading: isLoadingSaveHeader } =
    useSaveHeaderFormA();

  const { mutate: mutateDeleteFormA } = useDeleteAllFormA();

  const {
    mutateAsync: mutateAsyncImportHeader,
    isLoading: isLoadingImportHeader,
  } = useImportFormA();

  const [isVisible, setIsVisible] = useState(false);

  const [valueSelect, setValueSelect] = useState("");

  const [hiddenDataTable, setHiddenDataTable] = useState(null);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);

  const formExternalRef = useRef();
  const formInternalRef = useRef();

  // handle toogle modal
  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);

  const closeModal = useCallback(() => {
    setCurrentStep(0);
    setIsVisible(false);
    setExternalData({
      formType: DOCS_FORM.AC,
      numPerPage: 5,
      item_hs: true,
      item_name: true,
      user_input: true,
    });
    setInternalData(null);
    setFileName("");
    onResetValue();
    setFile(null);
  }, [setIsVisible]);

  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );

  // handle select sheet
  const onChangeSelect = (value) => {
    setValueSelect(value);
    setIsLoadingUpload(true);
  };

  // get form item
  const fieldsConfig = useImportSelectSheetConfig(
    sheetList,
    onChangeSelect,
    valueSelect
  );

  // handle select cell end & cell start
  const handleFilterTable = (_, allValues) => {
    const { end, start } = allValues;

    if (isValidFormatCellValue(start) && isValidFormatCellValue(end)) {
      // get index row & col by cell
      const addStartCell = excelIndex(start);
      const addEndCell = excelIndex(end);

      const hiddenList = convertHiddenList(addStartCell, addEndCell, sheetData);

      setHiddenDataTable({ addStartCell, addEndCell, ...hiddenList });
    }
  };

  // debounce
  const debounceHandleFilterTable = useCallback(
    _debounce(handleFilterTable, 1000),
    [sheetData]
  );

  // reset value
  const onResetValue = () => {
    setSheetData([]);
    setValueSelect(null);
    setHiddenDataTable(null);
  };

  // get header
  const getHeader = async () => {
    const formData = genBodyFormData({
      sheet_name: sheetName,
      header_from: selectHeader.header_from + 1,
      header_to: selectHeader.header_to + 1,
      end_row: hiddenDataTable?.addEndCell?.row + 1,
      company_slug: companyManagement?.company?.detail?.company?.slug || "",
      folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
    });
    formData.append("excel_file", file, file.path);

    const res = await mutateAsyncGetHeader(formData);
    await setHeadersData(res?.data);
    continueStep();
  };

  // save header
  const saveHeader = useCallback(async () => {
    await mutateAsyncSaveHeader({
      folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
      company_slug: companyManagement?.company?.detail?.company?.slug || "",
      map_headers: formSubmitMapHeaderData,
    });
  }, [formSubmitMapHeaderData, companyManagement]);

  // import ecus
  const onImport = async () => {
    const formData = genBodyFormData({
      sheet_name: sheetName,
      header_from: selectHeader.header_from + 1,
      header_to: selectHeader.header_to + 1,
      end_row: hiddenDataTable.addEndCell.row + 1,
      company_slug: companyManagement?.company?.detail?.company?.slug || "",
      folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
      imported_date: convertDateUni(new Date()),
    });

    formData.append("excel_file", file, file.path);

    await mutateAsyncImportHeader(formData, {
      onSuccess: async () => {
        message.success("Imported file successfully");
        setIsLoadingUpload(true);

        const params = {
          folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
          company_slug: companyManagement?.company?.detail?.company?.slug || "",
        };

        const res = await jwtAxios.get(URL_API.CO_FORM.GET_ALL, {
          params,
        });
        await setDataCoList(res?.results || []);
        setIsLoadingUpload(false);
        await mutateDeleteFormA({ ...params });
        continueStep();
      },
    });
  };

  const onFinish = async () => {
    switch (currentStep + 1) {
      case STEPS.EXTERNAL_INPUT.value:
        formExternalRef.current.form.submit();
        break;
      case STEPS.INTERNAL_INPUT.value:
        formInternalRef.current.form.submit();
        break;
      case STEPS.RESULT.value:
        closeModal();
        break;
      case STEPS.PICK_HEADER.value:
        getHeader();
        break;
      case STEPS.MAP_HEADER.value:
        onImport();
        break;
      default:
        continueStep();
        break;
    }
  };

  const onBack = async () => {
    switch (currentStep + 1) {
      case STEPS.IMPORT.value:
        setInternalData(null);
        break;
      case STEPS.SELECT_SHEET_TITLE.value:
        setFile(null);
        onResetValue();
        break;
      case STEPS.PICK_HEADER.value:
        onResetValue();
        break;
      case STEPS.MAP_HEADER.value:
        setHeadersConvertData([]);
        break;

      default:
        break;
    }
    prevStep();
  };

  const renderStep = () => {
    switch (currentStep + 1) {
      case STEPS.EXTERNAL_INPUT.value:
        return <ExternalInputModalImport ref={formExternalRef} />;
      case STEPS.INTERNAL_INPUT.value:
        return <InternalInputModalImport ref={formInternalRef} />;
      case STEPS.IMPORT.value:
        return (
          <ImportSheet
            setIsLoadingUpload={setIsLoadingUpload}
            setFile={setFile}
            file={file}
            setSheetList={setSheetList}
            setWorkBook={setWorkBook}
            title="3. Import sheet"
          />
        );
      case STEPS.SELECT_SHEET_TITLE.value:
        return (
          <SelectSheetTitle
            sheetData={sheetData}
            handleFilterTable={debounceHandleFilterTable}
            hiddenDataTable={hiddenDataTable}
            fieldsConfig={fieldsConfig}
            mergeCells={mergeCells}
            title="4. Select sheet title"
          />
        );
      case STEPS.PICK_HEADER.value:
        return (
          <SelectHeader
            sheetData={sheetData}
            hiddenDataTable={hiddenDataTable}
            setHeadersConvertData={setHeadersConvertData}
            setSelectHeader={setSelectHeader}
            mergeCells={mergeCells}
            title="5. Pick header row on Excel file"
          />
        );
      case STEPS.MAP_HEADER.value:
        return (
          <MapHeader
            saveHeader={saveHeader}
            headersData={headersData}
            headersConvertData={headersConvertData}
            setFormSubmitMapHeaderData={setFormSubmitMapHeaderData}
            title="6. Map headers"
          />
        );
      case STEPS.RESULT.value:
        return <ResultFormModalImport selectedGoodsRow={dataCoList} />;
      default:
        break;
    }
  };

  // back function
  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // handle continue
  const continueStep = () => {
    setCurrentStep(
      currentStep === Object.keys(STEPS).length - 1
        ? currentStep
        : currentStep + 1
    );
  };

  // check disable
  const disabledButton = useMemo(() => {
    switch (currentStep + 1) {
      case STEPS.INTERNAL_INPUT.value:
        return !!internalData?.mark_pack;
      case STEPS.IMPORT.value:
        return !!file;
      case STEPS.SELECT_SHEET_TITLE.value:
        return !!hiddenDataTable;
      case STEPS.PICK_HEADER.value:
        return headersConvertData?.length > 0;
      default:
        return true;
    }
  }, [hiddenDataTable, file, headersConvertData, currentStep, internalData]);

  useEffect(() => {
    if (valueSelect) {
      const wsname = valueSelect ? valueSelect : workBook.SheetNames[0];
      setSheetName(wsname);
      const ws = workBook.Sheets[wsname];

      const { validMergeCells, jsonData: jsonDataConvert } =
        convertShowData(ws);

      setSheetData(jsonDataConvert);
      setMergeCells(validMergeCells);
    }
    setIsLoadingUpload(false);
  }, [valueSelect]);

  return (
    <Modal
      open={isVisible}
      onCancel={closeModal}
      width={1368}
      bodyStyle={{ height: "80vh" }}
      className="modal"
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
    >
      <>
        <div className="modal-step">
          <Steps
            size="small"
            current={currentStep}
            items={Object.values(STEPS).map((step) => ({
              title: step.title,
            }))}
          />{" "}
        </div>

        <div>{renderStep()}</div>

        <div className="modal-btn">
          {currentStep > 0 && currentStep < Object.keys(STEPS).length - 1 && (
            <Button onClick={onBack}>
              &nbsp;
              <IntlMessages id="common.back" />
            </Button>
          )}
          &nbsp;
          <ButtonPermission
            type="primary"
            onClick={onFinish}
            disabled={!disabledButton}
          >
            &nbsp;
            {currentStep + 1 === STEPS.RESULT.value ? (
              <IntlMessages id="common.complete" />
            ) : (
              <IntlMessages id="common.continue" />
            )}
          </ButtonPermission>
        </div>
      </>
      {(isLoadingGetHeader ||
        isLoadingUpload ||
        isLoadingSaveHeader ||
        isLoadingImportHeader) && (
        <Spin
          spinning
          style={{
            ...centerItems,
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: " rgba(255, 255, 255, 0.5)",
            overflow: "hidden",
            zIndex: 99999999,
          }}
        />
      )}
    </Modal>
  );
};

export default forwardRef(DocsFormModalImport);
