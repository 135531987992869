import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import { Form } from "antd";
import "./index.style.less";

Quill.register("modules/imageResize", ImageResize);
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
const TextEditorInput = ({ value, onChange, placeholder }) => {
  const { status } = Form.Item.useStatus();
  return (
    <>
      <ReactQuill
        theme="snow"
        value={value || ""}
        modules={modules}
        formats={formats}
        bounds={"#root"}
        className={status === "error" ? "ql-error" : null}
        onChange={(content, delta, source, editor) => {
          onChange(editor.getLength() > 1 ? content : undefined);
        }}
        placeholder={placeholder}
      />
    </>
  );
};

export default TextEditorInput;
