import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";

export const getHeaderFormA = (body) => {
  return jwtAxios.post(URL_API.CO_FORM.GET_HEADER, body);
};

const useGetHeaderFormA = () => {
  const { messages } = useIntl();

  return useMutation({
    mutationFn: getHeaderFormA,
    onSuccess: () => {},
    onError: () => {
      message.error(messages["data.partner.getHeader.fail"]?.toString());
    },
  });
};

export default useGetHeaderFormA;
