import IntlMessages from "@uni/utility/IntlMessages";
import { Button, Modal, Steps } from "antd";
import {
  forwardRef,
  lazy,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DOCS_FORM } from "shared/constants/AppConst";
import {
  useDocsFormAActionContext,
  useDocsFormAContext,
} from "./DocsFormAProvider";
import "./index.style.less";

const ExternalInput = lazy(() => import("./ExternalInput"));
const InternalInput = lazy(() => import("./InternalInput"));
const ResultFormA = lazy(() => import("./ResultFormA"));

export const STEPS = {
  EXTERNAL_INPUT: {
    title: "Setting",
    value: 1,
  },
  INTERNAL_INPUT: {
    title: "Input Informations",
    value: 2,
  },
  RESULT: {
    title: "Generate",
    value: 3,
  },
};

const DocsFormA = ({ selectedGoodsRow }, ref) => {
  const {
    setExternalData,
    setInternalData,
    setFileName,
    setCurrentStep,
    prevStep,
  } = useDocsFormAActionContext();
  const { internalData, currentStep } = useDocsFormAContext();

  const [isVisible, setIsVisible] = useState(false);

  const formExternalRef = useRef();
  const formInternalRef = useRef();
  // handle toogle modal
  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);

  const closeModal = useCallback(() => {
    setCurrentStep(0);
    setIsVisible(false);
    setExternalData({
      formType: DOCS_FORM.AC,
      numPerPage: 5,
      item_hs: true,
      item_name: true,
      user_input: true,
    });
    setInternalData(null);
    setFileName("");
  }, [setIsVisible]);

  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );

  const onFinish = async () => {
    switch (currentStep + 1) {
      case STEPS.EXTERNAL_INPUT.value:
        formExternalRef.current.form.submit();
        break;
      case STEPS.INTERNAL_INPUT.value:
        formInternalRef.current.form.submit();
        break;
      case STEPS.RESULT.value:
        closeModal();
        break;
      default:
        break;
    }
  };

  const renderStep = () => {
    switch (currentStep + 1) {
      case STEPS.EXTERNAL_INPUT.value:
        return <ExternalInput ref={formExternalRef} />;
      case STEPS.INTERNAL_INPUT.value:
        return <InternalInput ref={formInternalRef} />;
      case STEPS.RESULT.value:
        return <ResultFormA selectedGoodsRow={selectedGoodsRow} />;
      default:
        break;
    }
  };

  return (
    <Modal
      open={isVisible}
      onCancel={closeModal}
      width={1368}
      bodyStyle={{ height: "80vh" }}
      className="modal"
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
    >
      <>
        <div className="modal-step">
          <Steps
            size="small"
            current={currentStep}
            items={Object.values(STEPS).map((step) => ({
              title: step.title,
            }))}
          />{" "}
        </div>

        <div>{renderStep()}</div>

        <div className="modal-btn">
          {currentStep > 0 && currentStep < Object.keys(STEPS).length - 1 && (
            <Button onClick={prevStep}>
              &nbsp;
              <IntlMessages id="common.back" />
            </Button>
          )}
          &nbsp;
          <Button
            type="primary"
            onClick={onFinish}
            disabled={currentStep === 1 && !internalData?.mark_pack}
          >
            &nbsp;
            {currentStep + 1 === STEPS.RESULT.value ? (
              <IntlMessages id="common.complete" />
            ) : (
              <IntlMessages id="common.continue" />
            )}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default forwardRef(DocsFormA);
