import React from "react";
import Determine from "./Determine";
import History from "./History";
import { GenerateProvider } from "@uni/utility/AppContextProvider/GenerateContextProvider";
import { PERMISSIONS } from "shared/constants/AppEnums";

export const deterConfig = [
  {
    path: "/determination/determine",
    element: (
      <GenerateProvider>
        <Determine />
      </GenerateProvider>
    ),
    scopes: [PERMISSIONS.VIEW_ECUS],
  },
  {
    path: "/determination/history",
    element: <History />,
    scopes: [PERMISSIONS.VIEW_BANGKE_HISTORY],
  },
];
