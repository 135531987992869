import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";

const deleteBulkStatement = async ({ ids }) =>
  await jwtAxios.delete(URL_API.BALANCE.DELETE_BULK, {
    data: { ids: ids },
  });

const useDeleteBulkStatement = () => {
  const queryClient = useQueryClient();
  const { messages } = useIntl();
  return useMutation({
    mutationFn: deleteBulkStatement,
    onSuccess: () => {
      queryClient.invalidateQueries(URL_API.BALANCE.GET_ALL);
      message.success(messages["common.delete.success"]?.toString());
    },
    onError: () => {
      message.error(messages["common.delete.fail"]?.toString());
    },
  });
};

export default useDeleteBulkStatement;
