import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useMutation } from "react-query";

const deleteAllFormA = (params) => {
  return jwtAxios.delete(URL_API.CO_FORM.DELETE_ALL, { data: { ...params } });
};

const useDeleteAllFormA = () => {
  return useMutation({
    mutationFn: deleteAllFormA,
    onSuccess: () => {},
    onError: () => {},
  });
};

export default useDeleteAllFormA;
