import { useCallback, useMemo, useState } from "react";
import { Button, Modal, Upload } from "antd";
import { getBase64 } from "../../utility/Utils";
import { UploadOutlined } from "@ant-design/icons";
import { APP_UPLOAD_FILE_MIME_TYPES } from "../../../shared/constants/AppConst";
import { FaTrash } from "react-icons/fa";

const PREVIEW_ICON_LINKS = {
  PDF: "/assets/images/pdf-icon.svg",
  WORD: "/assets/images/docx-icon.png",
  EXCEL: "/assets/images/excel-icon.svg",
  ERROR: "/assets/images/error-icon.png",
};

const getFilePreviewLink = (file) => {
  const mineType = file?.type;
  // console.log({ mineType });
  if (APP_UPLOAD_FILE_MIME_TYPES.IMAGE.includes(mineType))
    return file?.url || URL.createObjectURL(file?.originFileObj);
  else if (APP_UPLOAD_FILE_MIME_TYPES.PDF.includes(mineType))
    return PREVIEW_ICON_LINKS.PDF;
  else if (APP_UPLOAD_FILE_MIME_TYPES.WORD.includes(mineType))
    return PREVIEW_ICON_LINKS.WORD;
  else if (APP_UPLOAD_FILE_MIME_TYPES.EXCEL.includes(mineType))
    return PREVIEW_ICON_LINKS.EXCEL;
  else return PREVIEW_ICON_LINKS.ERROR;
};

const UploadItemRender = ({ file, onRemove, onPreview }) => {
  const name = file?.name;
  const previewLink = getFilePreviewLink(file);
  return (
    <div className="ant-upload-list-picture-container">
      <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture">
        <div className="ant-upload-list-item-info">
          <span className="ant-upload-span">
            <a
              className="ant-upload-list-item-thumbnail"
              rel="noopener noreferrer"
              onClick={() => onPreview(file)}
            >
              <img
                src={previewLink}
                alt={name}
                className="ant-upload-list-item-image"
              />
            </a>
            <span
              onClick={() => onPreview(file)}
              className="ant-upload-list-item-name"
              title={name}
            >
              {name}
            </span>
            <span className="ant-upload-list-item-card-actions picture">
              <button
                title="Remove file"
                type="button"
                onClick={() => onRemove(file)}
                className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
              >
                <FaTrash style={{ color: "red" }} />
              </button>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

const AppFileUpload = ({ value, onChange, onRemove }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const handleCancel = useCallback(() => setPreviewOpen(false), []);

  const handlePreview = useCallback(async (file) => {
    // console.log({ file });
    if (APP_UPLOAD_FILE_MIME_TYPES.IMAGE.includes(file.type)) {
      if (file?.url) {
        setPreviewImage(file.url);
        setPreviewOpen(true);
      } else {
        if (!file?.url && !file?.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.preview);
        setPreviewOpen(true);
      }
    } else {
      const url = file?.url || URL.createObjectURL(file?.originFileObj);
      window.open(url);
    }
  }, []);
  const accept = useMemo(
    () => Object.values(APP_UPLOAD_FILE_MIME_TYPES).join(", "),
    []
  );
  return (
    <>
      <Upload
        listType="picture"
        accept={accept}
        fileList={Array.isArray(value) ? value : []}
        multiple
        onRemove={onRemove}
        onChange={({ fileList }) => {
          onChange(fileList);
        }}
        itemRender={(_originNode, file, _fileList, { remove }) => (
          <UploadItemRender
            file={file}
            onRemove={remove}
            onPreview={handlePreview}
          />
        )}
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            onSuccess?.("ok");
          }, 0);
        }}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
      <Modal
        open={previewOpen}
        title="Preview Image"
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
export default AppFileUpload;
