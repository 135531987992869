import React, { createContext, forwardRef, useContext, useState } from "react";
import { DOCS_FORM } from "shared/constants/AppConst";

const DocsFormModalImportContext = createContext();
const DocsFormModalImportActionContext = createContext();

export const useDocsFormModalImportContext = () =>
  useContext(DocsFormModalImportContext);
export const useDocsFormModalImportActionContext = () =>
  useContext(DocsFormModalImportActionContext);

const DocsFormModalImport = ({ children }) => {
  const [externalData, setExternalData] = useState({
    formType: DOCS_FORM.AC,
    numPerPage: 5,
    item_hs: true,
    item_name: true,
    user_input: true,
  });

  const [internalData, setInternalData] = useState({
    refer: "",
    gw: "",
    company_exporter_name: "",
    company_exporter_address: "",
    company_consignee_name: "",
    company_consignee_address: "",
    label: "",
    depature_date: "",
    vessel_name: "",
    port_of_discharge: "",
    mark_pack: "",
    user_input_1: "",
    user_input_2: "",
    item_place: "",
    retroact: false,
    back: false,
    movem: false,
    exhi: false,
    cumu: false,
    third: false,
  });

  const [dataCoList, setDataCoList] = useState([]);

  const [fileName, setFileName] = useState("");
  const [sheetName, setSheetName] = useState("");
  const [file, setFile] = useState(null);
  const [sheetData, setSheetData] = useState([]);
  const [headersData, setHeadersData] = useState(null);
  const [headersConvertData, setHeadersConvertData] = useState([]);
  const [formSubmitMapHeaderData, setFormSubmitMapHeaderData] = useState(null);
  const [sheetList, setSheetList] = useState([]);
  const [selectHeader, setSelectHeader] = useState(0);
  const [mergeCells, setMergeCells] = useState([]);

  const [workBook, setWorkBook] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const prevStep = () => setCurrentStep(currentStep - 1);
  const nextStep = (step) =>
    setCurrentStep(
      currentStep === Object.keys(step).length - 1
        ? currentStep
        : currentStep + 1
    );

  return (
    <DocsFormModalImportContext.Provider
      value={{
        externalData,
        internalData,
        fileName,
        sheetName,
        file,
        sheetData,
        headersData,
        headersConvertData,
        formSubmitMapHeaderData,
        sheetList,
        selectHeader,
        mergeCells,
        workBook,
        dataCoList,
        currentStep,
      }}
    >
      <DocsFormModalImportActionContext.Provider
        value={{
          setExternalData,
          setInternalData,
          setFileName,
          setSheetName,
          setFile,
          setSheetData,
          setHeadersData,
          setHeadersConvertData,
          setFormSubmitMapHeaderData,
          setSheetList,
          setSelectHeader,
          setMergeCells,
          setWorkBook,
          setDataCoList,
          setCurrentStep,
          prevStep,
          nextStep,
        }}
      >
        {children}
      </DocsFormModalImportActionContext.Provider>
    </DocsFormModalImportContext.Provider>
  );
};

export default DocsFormModalImport;
