import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getAllEcusInvoice = async (params) => {
  const res = await jwtAxios.get(URL_API.ECUS.GET_ALL_INVOICE, { params });
  return res;
};

const useGetEcusInvoice = (params) =>
  useQuery(
    [URL_API.ECUS.GET_ALL_INVOICE, params],
    () => getAllEcusInvoice(params),
    { enabled: !!params.folder_slug && !!params.company_slug }
  );

export default useGetEcusInvoice;
