import krMessages from "../locales/ko_KR.json";

import koKR from "antd/lib/locale/ko_KR";

const KrLang = {
  messages: {
    ...krMessages,
  },
  antLocale: koKR,
  locale: "ko-KR",
};
export default KrLang;
