import { useLayoutActionsContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import { Form, Select } from "antd";
import useGetAllCompanyManagement from "hooks/apis/users/useGetAllCompanyManagement";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initialUrl } from "shared/constants/AppConst";
import { PLACEHOLDER_INPUT } from "shared/constants/SystemMessages";
import "./index.style.less";

const convertToCompanyList = (data) => {
  return data?.map((item) => ({
    value: item?.company?.pk,
    label: item?.company?.name,
    detail: item,
  }));
};

const AppSidebarSelect = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { data } = useGetAllCompanyManagement();
  const { updateCompanyManagement } = useLayoutActionsContext();

  const [companyOptions, setCompanyOptions] = useState([]);

  const onChange = (_, option) => {
    navigate(initialUrl);
    updateCompanyManagement((prev) => {
      const storageCompanyNameSpace = {
        ...prev,
        company: {
          detail: option.detail,
        },
      };
      localStorage.setItem("companyManagement", JSON.stringify(option));
      return storageCompanyNameSpace;
    });
  };

  useEffect(() => {
    if (data?.results?.length > 0) {
      // define list company
      const newOptions = convertToCompanyList(data?.results);
      setCompanyOptions(newOptions);

      const getStorageCompany = JSON.parse(
        localStorage.getItem("companyManagement")
      );

      // add default value is first item option
      const defaultValueOption = getStorageCompany || newOptions[0];
      form.setFieldsValue({ company: defaultValueOption?.detail?.company?.pk });

      updateCompanyManagement((prev) => ({
        ...prev,
        company: {
          detail: defaultValueOption.detail,
        },
      }));
    }
  }, [data]);

  return (
    <>
      <Form form={form} className="app-side-bar-select">
        <Form.Item name="company" labelAlign="left" labelCol={{ span: 24 }}>
          <Select
            className="app-side-bar-select-item"
            placeholder={PLACEHOLDER_INPUT.COMPANY}
            onChange={onChange}
            options={companyOptions}
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default AppSidebarSelect;
