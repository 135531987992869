import React, { createContext, useState, useContext } from "react";

// Tạo context
const GenerateContext = createContext();

// Tạo provider cho context
export const GenerateProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const nextStep = (data) => {
    setFormData({ ...formData, ...data });
    setCurrentStep(currentStep + 1);
  };
  const prevStep = () => setCurrentStep(currentStep - 1);

  return (
    <GenerateContext.Provider
      value={{
        formData,
        setFormData,
        setCurrentStep,
        currentStep,
        nextStep,
        prevStep,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </GenerateContext.Provider>
  );
};

// Hook để sử dụng context
export const useGenerateContext = () => useContext(GenerateContext);
