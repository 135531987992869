import React, { createContext, forwardRef, useContext, useState } from "react";
import { DOCS_FORM } from "shared/constants/AppConst";

const DocsFormAContext = createContext();
const DocsFormAActionContext = createContext();

export const useDocsFormAContext = () => useContext(DocsFormAContext);
export const useDocsFormAActionContext = () =>
  useContext(DocsFormAActionContext);

const DocsFormA = ({ children }) => {
  const [externalData, setExternalData] = useState({
    formType: DOCS_FORM.AC,
    numPerPage: 5,
    item_hs: true,
    item_name: true,
    user_input: true,
  });

  const [internalData, setInternalData] = useState({
    refer: "",
    gw: "",
    company_exporter_name: "",
    company_exporter_address: "",
    company_consignee_name: "",
    company_consignee_address: "",
    label: "",
    depature_date: "",
    vessel_name: "",
    port_of_discharge: "",
    mark_pack: "",
    user_input_1: "",
    user_input_2: "",
    item_place: "",
    retroact: false,
    back: false,
    movem: false,
    exhi: false,
    cumu: false,
    third: false,
  });

  const [fileName, setFileName] = useState("");
  const [currentStep, setCurrentStep] = useState(0);

  const prevStep = () => setCurrentStep(currentStep - 1);
  const nextStep = (step) =>
    setCurrentStep(
      currentStep === Object.keys(step).length - 1
        ? currentStep
        : currentStep + 1
    );

  return (
    <DocsFormAContext.Provider
      value={{ externalData, internalData, fileName, currentStep }}
    >
      <DocsFormAActionContext.Provider
        value={{
          setExternalData,
          setInternalData,
          setFileName,
          setCurrentStep,
          prevStep,
          nextStep,
        }}
      >
        {children}
      </DocsFormAActionContext.Provider>
    </DocsFormAContext.Provider>
  );
};

export default DocsFormA;
