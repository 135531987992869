import languageData from "./data";

import { Dropdown, Menu } from "antd";
import PropTypes from "prop-types";
import "./index.style.less";
import { FaUserAlt } from "react-icons/fa";
import { useAuthMethod, useAuthUser } from "@uni/utility/AuthHooks";
import { LoginOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

const AppUserInfo = () => {
  const { logout } = useAuthMethod();
  const { user } = useAuthUser();
  const { messages } = useIntl();

  const menu = (
    <Menu id="user-switcher" className="user-switcher">
      {user?.name && (
        <Menu.Item key="1">
          <div>{user?.name}</div>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="2" onClick={() => logout()}>
        <div className="logout">
          <LoginOutlined /> <div>{messages["common.logout"]}</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginLeft: "24px" }}>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        overlayStyle={{ zIndex: 1052 }}
      >
        <a className="ant-dropdown-link langBtn">
          <span className="lang-icon">
            <FaUserAlt color="#0A8FDC" fontSize={20} />
          </span>
        </a>
      </Dropdown>
    </div>
  );
};

export default AppUserInfo;

AppUserInfo.defaultProps = {
  iconOnly: false,
};

AppUserInfo.propTypes = {
  iconOnly: PropTypes.bool,
};
