import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";

const postSaveBangKe = (body) => jwtAxios.post(URL_API.BANG_KE.SAVE, body);

const useSavePostBangke = () => {
  const { messages } = useIntl();

  return useMutation({
    mutationFn: postSaveBangKe,
    onSuccess: () => {
      message.success(messages["bangke.modifySuccess"]?.toString());
    },
    onError: () => {
      message.error(messages["data.accounting.begin.create.fail"]?.toString());
    },
  });
};

export default useSavePostBangke;
