import AppCard from "@uni/core/AppCard";
import AppPageMetadata from "@uni/core/AppPageMetadata";
import { useAuthUser } from "@uni/utility/AuthHooks";
import { checkPermissionGranted } from "@uni/utility/Utils";
import { Tabs } from "antd";
import { useIntl } from "react-intl";
import Statement15 from "./Statement15";
import Statement15A from "./Statement15A";

const MakingStatement = () => {
  const { messages } = useIntl();
  const { user } = useAuthUser();

  const tabs = [
    {
      label: "STATEMENT 15",
      key: "1",
      children: <Statement15 />,
    },
    {
      label: "STATEMENT 15A",
      key: "2",
      children: <Statement15A />,
    },
  ];

  return (
    <div>
      <AppPageMetadata title={messages["sidebar.data.exim"]} />
      <AppCard>
        <Tabs
          defaultActiveKey="1"
          items={tabs.filter((tab) =>
            checkPermissionGranted({
              permissions: user?.permissionRoles?.permission,
              scopes: tab.scopes,
            })
          )}
        />
      </AppCard>
    </div>
  );
};

export default MakingStatement;
