// @ts-nocheck
import React from "react";
import { PERMISSIONS } from "shared/constants/AppEnums";

const Roles = React.lazy(() => import("./Roles"));
const Settings = React.lazy(() => import("./Settings"));

export const systemConfig = [
  // {
  //   path: "/setting/roles",
  //   element: <Roles />,
  //   scopes: [
  //     PERMISSIONS.CREATE_ROLE,
  //     PERMISSIONS.EDIT_ROLE,
  //     PERMISSIONS.DELETE_ROLE,
  //   ],
  // },
  {
    path: "/setting/account-profile",
    element: <Settings />,
    // scopes: [
    //   PERMISSIONS.CREATE_ROLE,
    //   PERMISSIONS.EDIT_ROLE,
    //   PERMISSIONS.DELETE_ROLE,
    // ],
  },
];
