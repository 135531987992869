import React from "react";
import "./shared/styles/richbeauty.less";
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from "./@uni";
import { BrowserRouter } from "react-router-dom";
import "./@uni/services/index";
import JWTAuthAuthProvider from "./@uni/services/auth/jwt-auth/JWTAuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 1000, // 5s
    },
  },
});

const App = () => (
  <AppContextProvider>
    <QueryClientProvider client={queryClient}>
      <AppThemeProvider>
        <AppLocaleProvider>
          <BrowserRouter>
            <JWTAuthAuthProvider>
              <AuthRoutes>
                <AppLayout />
              </AuthRoutes>
            </JWTAuthAuthProvider>
          </BrowserRouter>
        </AppLocaleProvider>
      </AppThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </AppContextProvider>
);

export default App;
