import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";

const postBangKeDefault = (body) => {
  if (body?.data?.[0]?.url_option === "default") {
    return jwtAxios.post(URL_API.BANG_KE.DEFAULT, body);
  }
  return jwtAxios.post(URL_API.BANG_KE.CUSTOM, body);
};

const usePostBangkeDefault = () => {
  const { messages } = useIntl();

  return useMutation({
    mutationFn: postBangKeDefault,
    onSuccess: () => {
      message.success(messages["bangke.generateSuccess"]?.toString());
    },
    onError: () => {
      message.error(messages["bangke.generateFail"]?.toString());
    },
  });
};

export default usePostBangkeDefault;
