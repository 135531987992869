import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getHistory = async (params) => {
  const res = await jwtAxios.get(URL_API.HISTORY.GET_ALL, { params });
  return res;
};

const useGetAllHistory = (params) =>
  useQuery([URL_API.HISTORY.GET_ALL, params], () => getHistory(params), {
    enabled: !!params?.folder_slug && !!params?.company_slug,
  });

export default useGetAllHistory;
