import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getStatementById = (id) =>
  jwtAxios.get(`${URL_API.BALANCE.GET_ONE}${id}/`);

const useGetStatementById = (id) => {
  const { data, ...rest } = useQuery([URL_API.BALANCE.GET_ONE, id], () =>
    getStatementById(id)
  );
  return { statement: data, ...rest };
};

export default useGetStatementById;
