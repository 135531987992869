import FieldItem from "./FieldItem";
import { AppRowContainer } from "../../index";

const TabForm = ({ fieldsConfig }) => {
  return (
    <AppRowContainer gutter={16}>
      {fieldsConfig.map((fieldConfig, index) => (
        <FieldItem key={index} fieldConfig={fieldConfig} />
      ))}
    </AppRowContainer>
  );
};

export default TabForm;
