import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getAllCompanyManagement = (params) =>
  jwtAxios.get(URL_API.COMPANY.COMPANY_MANAGEMENT, { params });

const useGetAllCompanyManagement = () => {
  return useQuery([URL_API.COMPANY.COMPANY_MANAGEMENT], () =>
    getAllCompanyManagement()
  );
};

export default useGetAllCompanyManagement;
