import useDebounce from "hooks/useDebounce";
import { memo, useEffect, useState } from "react";
import { Input } from "antd";

const AppDebouncedInputSearch = (props) => {
  const [event, setEvent] = useState();
  // eslint-disable-next-line no-unused-vars
  const { value, ...restProps } = props;
  const { debouncedValue } = useDebounce(event, 300);
  useEffect(() => {
    props?.onChange?.(debouncedValue);
    // eslint-disable-next-line
  }, [debouncedValue]);
  return <Input {...restProps} onChange={setEvent} />;
};

export default memo(AppDebouncedInputSearch);
