import {
  DeleteOutlined,
  ReloadOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import AppButtonActions from "@uni/core/AppButtonAction";
import AppPageMetadata from "@uni/core/AppPageMetadata";
import AppsContainer from "@uni/core/AppsContainer";
import AppsContent from "@uni/core/AppsContainer/AppsContent";
import AppsHeader from "@uni/core/AppsContainer/AppsHeader";
import AppTableContainer from "@uni/core/AppTableContainer";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import IntlMessages from "@uni/utility/IntlMessages";
import { Pagination } from "antd";
import useAppConfirmModal from "hooks/useAppConfirmModal";
import useAppShowHideTableColumn from "hooks/UseAppShowHideTableColumn";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { LimitDefault, OffsetDefault } from "shared/constants/AppConst";
import "./index.style.less";
import useDeleteBulkStatement from "hooks/apis/makingStatement/useDeleteBulkStatement";
import useGetAllStatement from "hooks/apis/makingStatement/useGetAllStatement";
import GenerateModal from "./GenerateModal";
import { GenerateProvider } from "./GenerateModal/GenerateContextProvider";
import Statement15TableSearch from "./Statement15TableSearch";
import useGetStatement15Columns from "./useGetStatement15Columns";
import ViewStatement15Detail from "./ViewStatement15Detail";
import { URL_API } from "@uni/services/endpoint";

const MAKING_STATEMENT_15_TABLE_KEY = "BalanceStatement15Table";
const Statement15 = () => {
  const { messages } = useIntl();
  const queryClient = useQueryClient();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const { companyManagement } = useLayoutContext();
  const viewGenerateModalRef = useRef();
  const [detailId, setDetailId] = useState("");

  const statement15Columns = useGetStatement15Columns({ setDetailId });

  // Params for API
  const [params, setParams] = useState({
    limit: LimitDefault,
    offset: OffsetDefault,
    search: "",
    logic_type: "Statement_15",
  });

  // API Call
  const { data: dataStatement15, isFetching: isFetchingStatement15 } =
    useGetAllStatement({
      ...params,
      folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
      company_slug: companyManagement?.company?.detail?.company?.slug || "",
    });
  const { filteredTableColumns, tableColumnDisplayConfigurator } =
    useAppShowHideTableColumn({
      tableKey: MAKING_STATEMENT_15_TABLE_KEY,
      tableColumns: statement15Columns,
    });
  const [columnsState, setColumnsState] = useState(() =>
    statement15Columns?.map((column, i) => ({
      ...column,
      key: `${i}`,
      onHeaderCell: () => ({
        id: `${i}`,
        draggable: column.draggable !== false,
      }),
      onCell: () => ({
        id: `${i}`,
      }),
    }))
  );
  const { mutate: mutateDeleteBulkStatement } = useDeleteBulkStatement();

  const { contextHolder: contextConfirmDelete, openModal: openConfirmDelete } =
    useAppConfirmModal();

  const onDelete = useCallback(() => {
    openConfirmDelete({
      title: <IntlMessages id="data.exim.statement15.deleteConfirm" />,
      okText: <IntlMessages id="common.delete" />,
      cancelText: <IntlMessages id="common.cancel" />,
      onOk: () => {
        mutateDeleteBulkStatement({ ids: selectedRowIds });
      },
    });
  }, [mutateDeleteBulkStatement, openConfirmDelete, selectedRowIds]);

  return (
    <>
      <AppPageMetadata title={messages["sidebar.balance.makingStatement"]} />

      <AppsContainer type="bottom" fullView>
        {!detailId ? (
          <>
            {" "}
            <AppsHeader>
              <div className="page-header">
                <Statement15TableSearch
                  onValuesChange={(_changeValues, allValues) =>
                    setParams((prev) => ({
                      ...prev,
                      ...allValues,
                      limit: LimitDefault,
                    }))
                  }
                />

                <AppButtonActions
                  // scopes={[PERMISSIONS.DELETE_TAG]}
                  icon={<DeleteOutlined />}
                  title={messages["common.delete"]}
                  disabled={!selectedRowIds.length}
                  onClick={onDelete}
                  type="text"
                />
                <AppButtonActions
                  // scopes={[PERMISSIONS.CREATE_TAG]}
                  icon={<ReloadOutlined />}
                  title={messages["common.refresh"]}
                  type="text"
                  onClick={() =>
                    queryClient.invalidateQueries([
                      URL_API.BALANCE.GET_ALL,
                      { logic_type: "Statement_15" },
                    ])
                  }
                />
                <div className="page-header-right">
                  <AppButtonActions
                    icon={<ToolOutlined />}
                    title={messages["common.generate"]}
                    type="primary"
                    onClick={() => viewGenerateModalRef.current?.openModal()}
                  />
                </div>
              </div>
            </AppsHeader>
            <AppsContent
              style={{
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <AppTableContainer
                loading={isFetchingStatement15}
                data={dataStatement15?.results}
                columns={filteredTableColumns}
                columnsState={columnsState}
                setColumnsState={setColumnsState}
                rowSelection={{
                  type: "checkbox",
                  onChange: (_, selectedRows) =>
                    setSelectedRowIds(
                      selectedRows?.map((row) => {
                        return row?._id;
                      })
                    ),
                }}
              />
            </AppsContent>
            <div className="custom-pagination">
              <Pagination
                defaultPageSize={20}
                current={Math.ceil(params.offset / params.limit) + 1}
                onChange={(page, pageSize) => {
                  setParams((prev) => {
                    return {
                      ...prev,
                      limit: pageSize,
                      offset: (page - 1) * pageSize,
                    };
                  });
                }}
                total={dataStatement15?.count}
                showTotal={(total, range) => {
                  return (
                    <p>
                      {range[0]}-{range[1]} {messages["common.on"]?.toString()}{" "}
                      {total} {messages["common.records"]?.toString()}
                    </p>
                  );
                }}
                showSizeChanger
              />
            </div>{" "}
          </>
        ) : (
          <ViewStatement15Detail id={detailId} setDetailId={setDetailId} />
        )}
      </AppsContainer>

      <GenerateProvider>
        <GenerateModal ref={viewGenerateModalRef} />
      </GenerateProvider>
      {contextConfirmDelete}
    </>
  );
};

export default Statement15;
