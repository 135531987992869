import { DownloadOutlined, LeftOutlined } from "@ant-design/icons";
import AppButtonActions from "@uni/core/AppButtonAction";
import IntlMessages from "@uni/utility/IntlMessages";
import { Button } from "antd";
import { useIntl } from "react-intl";
import AppShowTable from "../AppShowTable";
import "./index.style.less";

const AppViewDetail = ({
  setDetailId,
  downloadExcel,
  dataTable,
  children = <></>,
}) => {
  const { messages } = useIntl();

  return (
    <div className="app-view-detail">
      <div className="app-view-detail-header">
        <Button
          type="text"
          icon={<LeftOutlined />}
          className="btn-back"
          onClick={() => setDetailId("")}
        >
          <IntlMessages id="common.backToList" />
        </Button>
        <div className="header-right">
          <AppButtonActions
            icon={<DownloadOutlined />}
            title={messages["common.download"]}
            type="default"
            onClick={downloadExcel}
            disabled={!Object.keys(dataTable)?.length}
            style={{ marginBottom: "10px" }}
          />
        </div>
      </div>

      <>
        {dataTable?.data?.length > 0 && (
          <AppShowTable
            data={dataTable?.data || []}
            colWidths={dataTable?.colWidths || []}
            mergeCells={dataTable?.mergeCells || []}
            height={"70vh"}
            cell={dataTable?.formatCell || []}
            rowHeights={dataTable?.rowHeights || []}
          />
        )}
        {children}
      </>
    </div>
  );
};

export default AppViewDetail;
