import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import useIntl from "react-intl/lib/src/components/useIntl";
import { useMutation, useQueryClient } from "react-query";

const postDeduction = (body) => jwtAxios.post(URL_API.HISTORY.DEDUCTION, body);

const usePostDeduction = () => {
  const { messages } = useIntl();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postDeduction,
    onSuccess: () => {
      queryClient.invalidateQueries(URL_API.HISTORY.GET_ALL);
      message.success(messages["bangke.deduction.success"]?.toString());
    },
    onError: () => {
      message.error(messages["bangke.deduction.fail"]?.toString());
    },
  });
};

export default usePostDeduction;
