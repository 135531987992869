import axios from "axios";
import { httpStatus } from "shared/constants/API";

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
jwtAxios.interceptors.response.use(
  (res) => res?.data,
  (err) => {
    if (err.response && err.response.status === httpStatus.UNAUTHORIZED) {
      setAuthToken();
    }
    return Promise.reject(err?.response?.data);
  }
);
export const setAuthToken = (token, isRemember) => {
  if (token) {
    jwtAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    isRemember
      ? localStorage.setItem("token", token)
      : sessionStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
  }
};

export const genHeaderLogin = () => {
  const headersRequest = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  return {
    headers: { ...headersRequest },
  };
};

export const genHeader = (token) => {
  const headersRequest = {
    Authorization: `Bearer ${token}`,
  };

  return {
    headers: { ...headersRequest },
  };
};

export const genBodyFormUrlEncoded = (data) => {
  const urlencoded = new URLSearchParams();
  Object.entries(data).map(([key, value]) => {
    urlencoded.append(key, value);
  });
  return urlencoded;
};

export const genBodyFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).map(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};

export default jwtAxios;
