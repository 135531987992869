import React from "react";
import MakingStatement from "./MakingStatement";
const ManagementCode = React.lazy(() => import("./ManagementCode"));
const CheckingStatement = React.lazy(() => import("./CheckingStatement"));
const BalanceStatement = React.lazy(() => import("./BalanceStatement"));
const TaxRisk = React.lazy(() => import("./TaxRisk"));

export const balanceConfig = [
  {
    path: "/balance/management-code",
    element: <ManagementCode />,
  },
  {
    path: "/balance/tax-risk",
    element: <TaxRisk />,
  },
  {
    path: "/balance/checking-statement",
    element: <CheckingStatement />,
  },
  {
    path: "/balance/balance-statement",
    element: <BalanceStatement />,
  },
  {
    path: "/balance/making-statement",
    element: <MakingStatement />,
  },
];
