import HotTable from "@handsontable/react";
import { Empty } from "antd";
import { registerAllModules } from "handsontable/registry";
import { forwardRef } from "react";
registerAllModules();

const AppShowTable = (props, ref) => {
  return props?.data?.length > 0 ? (
    <HotTable
      className="htCenter htMiddle"
      colHeaders
      rowHeaders
      licenseKey="non-commercial-and-evaluation"
      stretchH="all"
      manualColumnResize
      manualColumnMove={false}
      width="100%"
      {...props}
      ref={ref}
    />
  ) : (
    <Empty />
  );
};

export default forwardRef(AppShowTable);
