import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";
import IntlMessages from "@uni/utility/IntlMessages";
import { Button, Select, Table, Typography } from "antd";
import usePostBangkeManual from "hooks/apis/bangke/usePostBangKeManual";
const { Text } = Typography;
import QueueAnim from "rc-queue-anim";
import ActionRow from "./ActionRow";
import useRecalculate from "hooks/apis/bangke/useRecalculate";

const EditTable = ({ data }) => {
  const { mutateAsync, isLoading } = usePostBangkeManual();
  const { mutateAsync: mutateRecalculate } = useRecalculate();
  const { modifyData, setModifyData, formData } = useGenerateContext();

  const updatePriceAndRecalculate = async (
    record,
    priceOption,
    originOption
  ) => {
    try {
      const indexItem = data?.bangke_data?.findIndex(
        (item) => item._id === record._id
      );
      const indexData = modifyData?.findIndex(
        (item) => item.ecus_input.ecus_id === data.ecus_input.ecus_id
      );

      const body = {
        ...data,
        bangke_data: data.bangke_data.find((item) => item._id === record._id),
        all_option: "false",
        price_option: priceOption,
        origin_option: originOption,
        url_option: formData.url_option,
      };

      const res = await mutateAsync({ body });

      const newModifyData = [
        ...modifyData.slice(0, indexData),
        {
          ...modifyData[indexData],
          bangke_data: [
            ...modifyData[indexData].bangke_data.slice(0, indexItem),
            {
              ...res?.results?.[0],
              prevPrice:
                record?.origin_price > 0
                  ? record?.origin_price?.toFixed(3)
                  : record?.non_origin_price?.toFixed(3),
            },
            ...modifyData[indexData].bangke_data.slice(indexItem + 1),
          ],
          prevOriginPrice: recalculate_args?.total_origin_price,
          prevNonOriginPrice: recalculate_args?.total_non_origin_price,
          prevFormulaResult: ecus_input?.formula_result,
        },
        ...modifyData.slice(indexData + 1),
      ];

      setModifyData(newModifyData);
      const res2 = await mutateRecalculate(newModifyData[indexData]);
      setModifyData([
        ...newModifyData.slice(0, indexData),
        {
          ...newModifyData[indexData],
          recalculate_args: res2?.recalculate_args,
          ecus_input: res2?.ecus_input,
        },
        ...newModifyData.slice(indexData + 1),
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLowerPrice = (record) => {
    return updatePriceAndRecalculate(
      record,
      "low_price",
      record?.origin_price > 0 ? "originated" : "non_originated"
    );
  };

  const handleHigherPrice = (record) => {
    return updatePriceAndRecalculate(
      record,
      "high_price",
      record?.origin_price > 0 ? "originated" : "non_originated"
    );
  };

  const handleOriginChange = (record, value) => {
    return updatePriceAndRecalculate(record, undefined, value);
  };

  const {
    bangke_data,
    recalculate_args,
    company_info,
    ecus_input,
    start_date,
    message_error,
    prevOriginPrice,
    prevNonOriginPrice,
    prevFormulaResult,
  } = data;

  const columns = [
    {
      title: <IntlMessages id="common.no" />,
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: <IntlMessages id="determination.determine.step4.nvl" />,
      dataIndex: "code",
      key: "code",
      width: 50,
    },
    {
      title: <IntlMessages id="determination.determine.step4.stk" />,
      dataIndex: "cd_number",
      key: "cd_number",
      width: 50,
    },
    {
      title: <IntlMessages id="determination.determine.step4.date" />,
      dataIndex: "cd_date",
      key: "cd_date",
      width: 50,
    },
    {
      title: <IntlMessages id="determination.determine.step4.hs" />,
      dataIndex: "hs_code",
      key: "hs_code",
      width: 50,
    },
    {
      title: <IntlMessages id="determination.determine.step4.cif" />,
      dataIndex: "unit_price",
      key: "unit_price",
      width: 50,
      render: (data) => data?.toFixed(3),
    },
    {
      title: <IntlMessages id="determination.determine.step4.fromCountry" />,
      dataIndex: "from_country",
      key: "from_country",
      width: 50,
    },
    {
      title: <IntlMessages id="determination.determine.step4.price" />,
      key: "non_origin_price",
      width: 50,
      render: (_, record) => {
        if (record?.origin_price > 0) {
          return record?.origin_price?.toFixed(3);
        }
        if (record?.non_origin_price > 0) {
          return record?.non_origin_price?.toFixed(3);
        }
        return 0;
      },
    },
    {
      title: <IntlMessages id="determination.determine.step4.prevPrice" />,
      key: "prevPrice",
      dataIndex: "prevPrice",
      width: 50,
    },
    {
      title: <IntlMessages id="determination.determine.optionOrigin" />,
      key: "optionOrigin",
      width: 100,
      render: (text, record, index) => (
        <Select
          style={{ width: "150px" }}
          value={record?.origin_price > 0 ? "originated" : "non_originated"}
          options={[
            { value: "originated", label: "Originated" },
            { value: "non_originated", label: "Non Originated" },
          ]}
          onChange={(value) => handleOriginChange(record, value)}
        />
      ),
    },
    {
      title: <IntlMessages id="determination.determine.optionPrice" />,
      dataIndex: "optionPrice",
      key: "optionPrice",
      width: 100,
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Button
            onClick={() => handleLowerPrice(record)}
            icon={<ArrowDownOutlined />}
          >
            Lower
          </Button>
          <Button
            onClick={() => handleHigherPrice(record)}
            icon={<ArrowUpOutlined />}
          >
            Higher
          </Button>
        </div>
      ),
    },
    {
      title: <IntlMessages id="determination.determine.listMaterial" />,
      key: "list",
      width: 120,
      render: (text, record, index) => (
        <ActionRow record={record} data={data} />
      ),
    },
  ];
  return (
    <>
      {bangke_data?.length > 0 ? (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              padding: "12px 20px",
              backgroundColor: "#E1F5FE",
              marginBottom: "20px",
              borderRadius: "4px",
            }}
          >
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <Text color="#6c6c6c">Tên sản phẩm</Text>
                <Text ellipsis={{ tooltip: ecus_input?.ecus_item_name }}>
                  {ecus_input?.ecus_item_name}
                </Text>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <Text color="#6c6c6c">FTA PSR:</Text>
                <Text>{recalculate_args?.criteria_value}</Text>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <Text color="#6c6c6c">HS:</Text>
                <Text>{ecus_input?.ecus_hs}</Text>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <Text color="#6c6c6c">Số lượng:</Text>
                <Text>{ecus_input?.ecus_total_quantity}</Text>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <Text color="#6c6c6c">Trị giá:</Text>
                <Text>{Number(ecus_input?.formula_nt_value).toFixed(3)}</Text>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <div></div>
                <Text color="#6c6c6c">Current</Text>
                <Text color="#6c6c6c">Previous</Text>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <Text color="#6c6c6c">Trị giá USD - Có xuất xứ FTA:</Text>
                <Text>{recalculate_args?.total_origin_price.toFixed(3)}</Text>
                <Text>{prevOriginPrice?.toFixed(3)}</Text>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <Text color="#6c6c6c">Trị giá USD - Không có xuất xứ FTA:</Text>
                <Text>
                  {recalculate_args?.total_non_origin_price.toFixed(3)}
                </Text>
                <Text>{prevNonOriginPrice?.toFixed(3)}</Text>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  borderBottom: "1px solid #0000000f",
                  paddingBlock: "6px",
                }}
              >
                <Text color="#6c6c6c">Tổng giá trị USD:</Text>
                <Text>
                  {Number(
                    recalculate_args?.total_origin_price +
                      recalculate_args?.total_non_origin_price
                  ).toFixed(3)}
                </Text>
                <Text>
                  {prevOriginPrice &&
                    Number(prevOriginPrice + prevNonOriginPrice)?.toFixed(3)}
                </Text>
              </div>
              {recalculate_args?.form_criteria === "RVC" && (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    borderBottom: "1px solid #0000000f",
                    paddingBlock: "6px",
                  }}
                >
                  <Text color="#6c6c6c">RVC %:</Text>
                  <Text>{ecus_input?.formula_result?.toFixed(2)} %</Text>
                  <Text>{prevFormulaResult?.toFixed(2)} %</Text>
                </div>
              )}
            </div>
          </div>

          <QueueAnim>
            <Table
              loading={isLoading}
              scroll={{ x: "max-content", y: "25vh" }}
              className="table-step-two"
              rowKey="id"
              columns={columns}
              dataSource={bangke_data}
              pagination={false}
              size="small"
            />
          </QueueAnim>
        </>
      ) : (
        <div className="error-wrapper">
          <div className="empty-text">Empty Data</div>
          {message_error && (
            <div className="error-text">Detail: {message_error}</div>
          )}
        </div>
      )}
    </>
  );
};

export default EditTable;
