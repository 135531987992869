// making statement 15
export const MERGE_CELLS_MAKING_STATEMENT15 = [
  {
    row: 0,
    col: 6,
    rowspan: 1,
    colspan: 4,
  },
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 10,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 11,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_MAKING_STATEMENT15 = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  { row: 0, col: 3, className: "htCenter htMiddle htBold" },
  { row: 0, col: 4, className: "htCenter htMiddle htBold" },
  { row: 0, col: 5, className: "htCenter htMiddle htBold" },
  { row: 0, col: 6, className: "htCenter htMiddle htBold" },
  { row: 0, col: 10, className: "htCenter htMiddle htBold" },
  { row: 0, col: 11, className: "htCenter htMiddle htBold" },
  { row: 1, col: 6, className: "htCenter htMiddle htBold" },
  { row: 1, col: 7, className: "htCenter htMiddle htBold" },
  { row: 1, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 9, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_MAKING_STATEMENT15 = [
  50, 100, 200, 100, 100, 100, 100, 100, 100, 100, 100, 200,
];

export const COL_EXCEL_WIDTHS_MAKING_STATEMENT15 = [
  5, 20, 20, 20, 15, 15, 15, 15, 15, 15, 15, 20,
];

// making statement 15A
export const MERGE_CELLS_MAKING_STATEMENT15A = [
  {
    row: 0,
    col: 6,
    rowspan: 1,
    colspan: 3,
  },
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 9,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 10,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_MAKING_STATEMENT15A = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  { row: 0, col: 3, className: "htCenter htMiddle htBold" },
  { row: 0, col: 4, className: "htCenter htMiddle htBold" },
  { row: 0, col: 5, className: "htCenter htMiddle htBold" },
  { row: 0, col: 6, className: "htCenter htMiddle htBold" },
  { row: 0, col: 9, className: "htCenter htMiddle htBold" },
  { row: 0, col: 10, className: "htCenter htMiddle htBold" },
  { row: 0, col: 11, className: "htCenter htMiddle htBold" },
  { row: 1, col: 6, className: "htCenter htMiddle htBold" },
  { row: 1, col: 7, className: "htCenter htMiddle htBold" },
  { row: 1, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 9, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_MAKING_STATEMENT15A = [
  50, 100, 200, 100, 100, 100, 100, 100, 100, 100, 100, 200,
];

export const COL_EXCEL_WIDTHS_MAKING_STATEMENT15A = [
  5, 15, 20, 15, 15, 15, 15, 15, 15, 15, 20,
];

// balance
export const MERGE_CELLS_BALANCE = [
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 6,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 7,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 12,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 13,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 16,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 17,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 18,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 19,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 20,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 21,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 22,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 23,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 1,
    colspan: 2,
  },
  {
    row: 0,
    col: 4,
    rowspan: 1,
    colspan: 2,
  },
  {
    row: 0,
    col: 8,
    rowspan: 1,
    colspan: 2,
  },
  {
    row: 0,
    col: 10,
    rowspan: 1,
    colspan: 2,
  },
  {
    row: 0,
    col: 14,
    rowspan: 1,
    colspan: 2,
  },
];

export const FORMAT_CELLS_BALANCE = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  { row: 0, col: 4, className: "htCenter htMiddle htBold" },
  { row: 0, col: 6, className: "htCenter htMiddle htBold" },
  { row: 0, col: 7, className: "htCenter htMiddle htBold" },
  { row: 0, col: 8, className: "htCenter htMiddle htBold" },
  { row: 0, col: 10, className: "htCenter htMiddle htBold" },
  { row: 0, col: 12, className: "htCenter htMiddle htBold" },
  { row: 0, col: 13, className: "htCenter htMiddle htBold" },
  { row: 0, col: 14, className: "htCenter htMiddle htBold" },
  { row: 0, col: 15, className: "htCenter htMiddle htBold" },
  { row: 0, col: 16, className: "htCenter htMiddle htBold" },
  { row: 0, col: 17, className: "htCenter htMiddle htBold" },
  { row: 0, col: 18, className: "htCenter htMiddle htBold" },
  { row: 0, col: 19, className: "htCenter htMiddle htBold" },
  { row: 0, col: 20, className: "htCenter htMiddle htBold" },
  { row: 0, col: 21, className: "htCenter htMiddle htBold" },
  { row: 0, col: 22, className: "htCenter htMiddle htBold" },
  { row: 0, col: 23, className: "htCenter htMiddle htBold" },

  { row: 1, col: 2, className: "htCenter htMiddle htBold" },
  { row: 1, col: 3, className: "htCenter htMiddle htBold" },
  { row: 1, col: 4, className: "htCenter htMiddle htBold" },
  { row: 1, col: 5, className: "htCenter htMiddle htBold" },
  { row: 1, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 9, className: "htCenter htMiddle htBold" },
  { row: 1, col: 10, className: "htCenter htMiddle htBold" },
  { row: 1, col: 11, className: "htCenter htMiddle htBold" },
  { row: 1, col: 14, className: "htCenter htMiddle htBold" },
  { row: 1, col: 15, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_BALANCE = [
  100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
  100, 100, 100, 100, 100, 100, 100, 100, 100,
];

export const COL_EXCEL_WIDTHS_BALANCE = [
  20, 20, 20, 20, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
  15, 15, 15, 15, 15,
];

// checking statement 15
// bcqt
export const MERGE_CELLS_BCQT_STATEMENT15 = [
  {
    row: 0,
    col: 6,
    rowspan: 1,
    colspan: 4,
  },
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 10,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 11,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_BCQT_STATEMENT15 = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  { row: 0, col: 3, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 0,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 0,
    col: 10,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 0,
    col: 11,
    className: "htCenter htMiddle htBold ",
  },
  {
    row: 1,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 1,
    col: 7,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 1,
    col: 8,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 1,
    col: 9,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  { row: 2, col: 0, className: "htCenter htMiddle htBold" },
  { row: 2, col: 1, className: "htCenter htMiddle htBold" },
  { row: 2, col: 2, className: "htCenter htMiddle htBold" },
  { row: 2, col: 3, className: "htCenter htMiddle htBold" },
  { row: 2, col: 4, className: "htCenter htMiddle htBold" },
  { row: 2, col: 5, className: "htCenter htMiddle htBold" },
  { row: 2, col: 6, className: "htCenter htMiddle htBold" },
  { row: 2, col: 7, className: "htCenter htMiddle htBold" },
  { row: 2, col: 8, className: "htCenter htMiddle htBold" },
  { row: 2, col: 9, className: "htCenter htMiddle htBold" },
  { row: 2, col: 10, className: "htCenter htMiddle htBold" },
  { row: 2, col: 11, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_BCQT_STATEMENT15 = [
  50, 100, 200, 100, 100, 100, 100, 100, 100, 100, 150, 200,
];

export const COL_EXCEL_WIDTHS_BCQT_STATEMENT15 = [
  5, 20, 20, 20, 15, 15, 15, 15, 15, 15, 15, 20,
];
// begin
export const MERGE_CELLS_BEGIN_STATEMENT15 = [
  {
    row: 0,
    col: 7,
    rowspan: 1,
    colspan: 3,
  },
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 6,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_BEGIN_STATEMENT15 = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 0,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  { row: 0, col: 7, className: "htCenter htMiddle htBold" },
  { row: 1, col: 7, className: "htCenter htMiddle htBold" },
  { row: 1, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 9, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_BEGIN_STATEMENT15 = [
  100, 200, 100, 100, 100, 100, 100, 100, 100, 100,
];

export const COL_EXCEL_WIDTHS_BEGIN_STATEMENT15 = [
  20, 20, 20, 15, 15, 15, 15, 17, 15, 15, 15, 15,
];

// take in
export const MERGE_CELLS_TAKE_IN_STATEMENT15 = [
  {
    row: 0,
    col: 8,
    rowspan: 1,
    colspan: 4,
  },
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 6,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 7,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_TAKE_IN_STATEMENT15 = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 0,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 7,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  { row: 0, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 7, className: "htCenter htMiddle htBold" },
  { row: 1, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 9, className: "htCenter htMiddle htBold" },
  { row: 1, col: 10, className: "htCenter htMiddle htBold" },
  { row: 1, col: 11, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_TAKE_IN_STATEMENT15 = [
  100, 200, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
];

export const COL_EXCEL_WIDTHS_TAKE_IN_STATEMENT15 = [
  20, 20, 20, 15, 15, 15, 15, 15, 20, 15, 15, 15,
];

// take out
export const MERGE_CELLS_TAKE_OUT_STATEMENT15 = [
  {
    row: 0,
    col: 3,
    rowspan: 1,
    colspan: 4,
  },
  {
    row: 0,
    col: 9,
    rowspan: 1,
    colspan: 2,
  },
  {
    row: 0,
    col: 12,
    rowspan: 1,
    colspan: 5,
  },
  {
    row: 1,
    col: 14,
    rowspan: 1,
    colspan: 2,
  },
  {
    row: 0,
    col: 0,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 0,
    col: 7,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 0,
    col: 8,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 1,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 6,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 9,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 10,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 11,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_TAKE_OUT_STATEMENT15 = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 0,
    col: 7,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 8,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 9,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 11,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  { row: 0, col: 12, className: "htCenter htMiddle htBold" },

  {
    row: 1,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 1,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 1,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 1,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 1,
    col: 9,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 1,
    col: 10,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 1,
    col: 11,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  { row: 1, col: 12, className: "htCenter htMiddle htBold" },
  { row: 1, col: 13, className: "htCenter htMiddle htBold" },
  { row: 1, col: 14, className: "htCenter htMiddle htBold" },
  { row: 1, col: 16, className: "htCenter htMiddle htBold" },

  { row: 2, col: 12, className: "htCenter htMiddle htBold" },
  { row: 2, col: 13, className: "htCenter htMiddle htBold" },
  { row: 2, col: 14, className: "htCenter htMiddle htBold" },
  { row: 2, col: 15, className: "htCenter htMiddle htBold" },
  { row: 2, col: 16, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_TAKE_OUT_STATEMENT15 = [
  100, 300, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
  100, 100,
];

export const COL_EXCEL_WIDTHS_TAKE_OUT_STATEMENT15 = [
  20, 20, 20, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
];

// inventory
export const MERGE_CELLS_INVENTORY_STATEMENT15 = [
  {
    row: 0,
    col: 8,
    rowspan: 1,
    colspan: 4,
  },
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 6,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 7,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_INVENTORY_STATEMENT15 = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-orange",
  },
  {
    row: 0,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  {
    row: 0,
    col: 7,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-blue",
  },
  { row: 0, col: 8, className: "htCenter htMiddle htBold" },

  { row: 1, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 9, className: "htCenter htMiddle htBold" },
  { row: 1, col: 10, className: "htCenter htMiddle htBold" },
  { row: 1, col: 11, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_INVENTORY_STATEMENT15 = [
  100, 300, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
];

export const COL_EXCEL_WIDTHS_INVENTORY_STATEMENT15 = [
  20, 20, 20, 15, 15, 15, 15, 15, 15, 15, 15, 15,
];

// checking statement 15A
// BCQT
export const MERGE_CELLS_BCQT_STATEMENT15A = [
  {
    row: 0,
    col: 6,
    rowspan: 1,
    colspan: 3,
  },
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 9,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 10,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_BCQT_STATEMENT15A = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  { row: 0, col: 3, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 0,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 0,
    col: 9,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 0,
    col: 10,
    className: "htCenter htMiddle htBold",
  },
  {
    row: 1,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 1,
    col: 7,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 1,
    col: 8,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 1,
    col: 9,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  { row: 2, col: 0, className: "htCenter htMiddle htBold " },
  { row: 2, col: 1, className: "htCenter htMiddle htBold " },
  { row: 2, col: 2, className: "htCenter htMiddle htBold " },
  { row: 2, col: 3, className: "htCenter htMiddle htBold " },
  { row: 2, col: 4, className: "htCenter htMiddle htBold " },
  { row: 2, col: 5, className: "htCenter htMiddle htBold " },
  { row: 2, col: 6, className: "htCenter htMiddle htBold " },
  { row: 2, col: 7, className: "htCenter htMiddle htBold " },
  { row: 2, col: 8, className: "htCenter htMiddle htBold " },
  { row: 2, col: 9, className: "htCenter htMiddle htBold " },
  { row: 2, col: 10, className: "htCenter htMiddle htBold " },
  { row: 2, col: 11, className: "htCenter htMiddle htBold " },
];

// begin
export const FORMAT_CELLS_BEGIN_STATEMENT15A = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 0,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  { row: 0, col: 7, className: "htCenter htMiddle htBold" },
  { row: 1, col: 7, className: "htCenter htMiddle htBold" },
  { row: 1, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 9, className: "htCenter htMiddle htBold" },
];

// take in
export const MERGE_CELLS_TAKE_IN_STATEMENT15A = [
  {
    row: 0,
    col: 6,
    rowspan: 1,
    colspan: 2,
  },
  {
    row: 0,
    col: 0,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 0,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_TAKE_IN_STATEMENT15A = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 0,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold",
  },
  {
    row: 0,
    col: 7,
    className: "htCenter htMiddle htBold",
  },
  { row: 0, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 6, className: "htCenter htMiddle htBold" },
  { row: 1, col: 7, className: "htCenter htMiddle htBold" },
];

// take out
export const MERGE_CELLS_TAKE_OUT_STATEMENT15A = [
  {
    row: 0,
    col: 3,
    rowspan: 1,
    colspan: 3,
  },
  {
    row: 0,
    col: 7,
    rowspan: 1,
    colspan: 2,
  },
  {
    row: 0,
    col: 12,
    rowspan: 1,
    colspan: 6,
  },
  {
    row: 1,
    col: 12,
    rowspan: 1,
    colspan: 4,
  },
  {
    row: 0,
    col: 0,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 0,
    col: 1,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 0,
    col: 2,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 0,
    col: 6,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 0,
    col: 11,
    rowspan: 3,
    colspan: 1,
  },
  {
    row: 1,
    col: 3,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 4,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 5,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 7,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 8,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 9,
    rowspan: 2,
    colspan: 1,
  },
  {
    row: 1,
    col: 10,
    rowspan: 2,
    colspan: 1,
  },
];

export const FORMAT_CELLS_TAKE_OUT_STATEMENT15A = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 7,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 8,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 9,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 10,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 11,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  { row: 0, col: 12, className: "htCenter htMiddle htBold" },

  {
    row: 1,
    col: 3,
    className: "htCenter htMiddle htBold  custom-cell-statement-bg-yellow",
  },
  {
    row: 1,
    col: 4,
    className: "htCenter htMiddle htBold  custom-cell-statement-bg-yellow",
  },
  {
    row: 1,
    col: 5,
    className: "htCenter htMiddle htBold  custom-cell-statement-bg-yellow",
  },
  {
    row: 1,
    col: 7,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 1,
    col: 8,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 1,
    col: 9,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 1,
    col: 10,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 1,
    col: 11,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  { row: 1, col: 12, className: "htCenter htMiddle htBold" },
  { row: 1, col: 16, className: "htCenter htMiddle htBold" },
  { row: 1, col: 17, className: "htCenter htMiddle htBold" },

  { row: 2, col: 12, className: "htCenter htMiddle htBold" },
  { row: 2, col: 13, className: "htCenter htMiddle htBold" },
  { row: 2, col: 14, className: "htCenter htMiddle htBold" },
  { row: 2, col: 15, className: "htCenter htMiddle htBold" },
  { row: 2, col: 16, className: "htCenter htMiddle htBold" },
  { row: 2, col: 17, className: "htCenter htMiddle htBold" },
];

export const COL_WIDTHS_TAKE_OUT_STATEMENT15A = [
  100, 300, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
  100, 100, 100,
];

// inventory
export const FORMAT_CELLS_INVENTORY_STATEMENT15A = [
  { row: 0, col: 0, className: "htCenter htMiddle htBold" },
  { row: 0, col: 1, className: "htCenter htMiddle htBold" },
  { row: 0, col: 2, className: "htCenter htMiddle htBold" },
  {
    row: 0,
    col: 3,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-yellow",
  },
  {
    row: 0,
    col: 4,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 5,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 6,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  {
    row: 0,
    col: 7,
    className: "htCenter htMiddle htBold custom-cell-statement-bg-green",
  },
  { row: 0, col: 8, className: "htCenter htMiddle htBold" },

  { row: 1, col: 8, className: "htCenter htMiddle htBold" },
  { row: 1, col: 9, className: "htCenter htMiddle htBold" },
  { row: 1, col: 10, className: "htCenter htMiddle htBold" },
  { row: 1, col: 11, className: "htCenter htMiddle htBold" },
];
