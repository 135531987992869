import ComingSoon from "pages/errorPages/ComingSoon";
import React from "react";
const Exim = React.lazy(() => import("./Exim"));
const Accounting = React.lazy(() => import("./Accounting"));
const Bom = React.lazy(() => import("./Bom"));
const Warehouse = React.lazy(() => import("./Warehouse"));
const Others = React.lazy(() => import("./Others"));

export const dataConfig = [
  {
    path: "/data/exim",
    element: <Exim />,
  },
  {
    path: "/data/accounting",
    element: <Accounting />,
  },
  {
    path: "/data/bom",
    element: <Bom />,
  },
  {
    path: "/data/warehouse",
    element: <Warehouse />,
  },
  {
    path: "/data/others",
    element: <Others />,
  },
  {
    path: "/data/production",
    element: <ComingSoon />,
  },
];
