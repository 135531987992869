import IntlMessages from "@uni/utility/IntlMessages";
import { TABLE_ACTION_COLUMN_KEY } from "shared/constants/AppConst";
import Statement15ColumnActionOption from "./Statement15ColumnActionOption";

const useGetStatement15Columns = ({ setDetailId }) => {
  return [
    {
      title: <IntlMessages id="balance.making-balance.type" />,
      dataIndex: ["input", "file_type"],
      key: "type",
      width: 200,
    },
    {
      title: <IntlMessages id="balance.making-balance.import" />,
      dataIndex: ["input", "import_option"],
      key: "import",
      width: 200,
    },
    {
      title: <IntlMessages id="balance.making-balance.export" />,
      dataIndex: ["input", "export_option"],
      key: "export",
      width: 200,
    },
    {
      title: <IntlMessages id="balance.making-balance.startDate" />,
      dataIndex: ["input", "start_date"],
      key: "startDate",
      width: 150,
    },
    {
      title: <IntlMessages id="balance.making-balance.endDate" />,
      dataIndex: ["input", "end_date"],
      key: "endDate",
      width: 150,
    },
    {
      title: <IntlMessages id="common.action" />,
      key: TABLE_ACTION_COLUMN_KEY,
      width: 150,
      fixed: "right",
      render: (_, record) => (
        <Statement15ColumnActionOption
          id={record?._id}
          setDetailId={setDetailId}
        />
      ),
    },
  ];
};

export default useGetStatement15Columns;
