export const DEFAULT_GRANT_TYPE = "password";
export const DEFAULT_CLIENT_ID = "qA2KqTyfAVDGM2pQCSPUby1cA1C4QPIMs1Dx8W7a";
export const DEFAULT_CLIENT_SECRET = "ThereAreNoSecret6769!@";
export const httpStatus = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  GONE: 410,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
};

export const VERIFICATION_TYPE = {
  REGISTER: "register",
  FORGOT: "forgot",
};

export const PAGES_ROUTER = {
  REGISTER: "/register",
  FORGOT: "/forgot",
  LOGIN: "/login",
  VERIFY: "/verify",
  CHANGE_PASSWORD: "/change-password",
  DASHBOARD: "/dashboards",
};
