import enLang from "./entries/en-US";
import viLang from "./entries/vi_VN";
import krLang from "./entries/ko_KR";

const AppLocale = {
  en: enLang,
  vi: viLang,
  ko: krLang,
};

export default AppLocale;
