import React from "react";
import ContentLoader from "react-content-loader";

export const FormItemSke = (props) => (
  <ContentLoader viewBox="0 0 400 25" {...props}>
    <rect height="10" rx="1" ry="0" width="100" x="30" y="12" />
    <rect height="30" rx="1" ry="0" width="170" x="180" y="10" />
  </ContentLoader>
);
const FormItemListSkeleton = () => {
  return (
    <React.Fragment>
      <FormItemSke />
      <FormItemSke />
      <FormItemSke />
      <FormItemSke />
      <FormItemSke />
      <FormItemSke />
      <FormItemSke />
      <FormItemSke />
      <FormItemSke />
      <FormItemSke />
    </React.Fragment>
  );
};

export default FormItemListSkeleton;
