export const authRole = {
  admin: ["user", "admin"],
  user: ["user"],
};

export const RoutePermittedRole = {
  admin: "admin",
  user: "user",
};

export const ThemeStyle = {
  MODERN: "modern",
  STANDARD: "standard",
};

export const ThemeMode = {
  LIGHT: "light",
  SEMI_DARK: "semi-dark",
  DARK: "dark",
};
export const LayoutType = {
  FULL_WIDTH: "full-width",
  BOXED: "boxed",
  FRAMED: "framed",
};

export const MenuStyle = {
  DEFAULT: "default",
  STANDARD: "standard",
  ROUNDED: "rounded",
  ROUNDED_REVERSE: "rounded-reverse",
  CURVED_MENU: "curved-menu",
};

export const NavStyle = {
  DEFAULT: "default",
  MINI: "mini",
  MINI_SIDEBAR_TOGGLE: "mini-sidebar-toggle",
  STANDARD: "standard",
  HEADER_USER: "user-header",
  HEADER_USER_MINI: "user-mini-header",
  DRAWER: "drawer",
  BIT_BUCKET: "bit-bucket",
  H_DEFAULT: "h-default",
  HOR_HEADER_FIXED: "hor-header-fixed",
  HOR_DARK_LAYOUT: "hor-dark-layout",
};
export const FooterType = {
  FIXED: "fixed",
  FLUID: "fluid",
};
export const ThemeDirection = {
  RTL: "rtl",
  LTR: "ltr",
};

export const Fonts = {
  LIGHT: "300",
  REGULAR: "400",
  MEDIUM: "500",
  BOLD: "600",
  EXTRA_BOLD: "600",
};

export const S3Type = {
  BLOG: "blog",
  UID_AVATAR: "uid_avt",
  BANNER: "banners",
  PRODUCT: "product",
  ORDER: "order",
  WSID_FAVICON: "wsid_favicon",
  WSID_LOGO: "wsid_logo",
  WSID_FAVICON_MAC: "wsid_favicon_mac",
  WSID_LOGO_MAC: "wsid_logo_mac",
  REVIEW: "review",
  PRODUCT_STOCK: "product_stock",
};

export const BannerMediaType = {
  IMAGE: "image",
  IMAGE_DETAIL: "imageDetail",
  VIDEO: "video",
};

export const ANTD_TABLE_ON_CHANGE_ACTION = {
  PAGINATE: "paginate",
  SORT: "sort",
  FILTER: "filter",
};

export const AppInputType = {
  TEXT: "text",
  TEXT_AREA: "text_area",
  NUMBER: "number",
  PASSWORD: "password",
  SELECT: "select",
  UPLOAD_SINGLE_IMAGE: "uploadSingleImage",
  UPLOAD_MULTI_IMAGE: "uploadMultiImage",
  EDITOR: "editor",
  CHECKBOX: "checkbox",
  MULTISELECT: "multiselect",
  MULTISELECT_WITH_DATA: "multiselectWithData",
  SELECT_WITH_DATA: "selectWithData",
  DATE_PICKER: "datePicker",
  TIME_PICKER: "timePicker",
  RATING: "rating",
  RADIO: "radio",
  AUTO_COMPLETE: "autoComplete",
  BUTTON: "button",
  TREE_SELECT: "treeSelect",
  TREE_SELECT_DISPLAY_LIST: "treeSelectDisplayList",
  SELECT_DISPLAY_LIST: "selectDisplayList",
  PHONE_NUMBER_INPUT: "phoneNumberInput",
  FILE_UPLOAD: "fileUpload",
  SWITCH: "switch",
};

export const SortType = {
  ASC: "asc",
  DESC: "desc",
};

export const PERMISSIONS = {
  CREATE_ROLE: "create-role",
  EDIT_ROLE: "edit-role",
  DELETE_ROLE: "delete-role",
  VIEW_DASHBOARD: "Can view dashboard",

  // uni permission
  ADD_ANNEX: "Can add annex",
  EDIT_ANNEX: "Can change annex",
  DELETE_ANNEX: "Can delete annex",
  VIEW_ANNEX: "Can view annex",

  ADD_BEGIN: "Can add begin",
  EDIT_BEGIN: "Can change begin",
  DELETE_BEGIN: "Can delete begin",
  VIEW_BEGIN: "Can view begin",

  ADD_BOM: "Can add bom",
  EDIT_BOM: "Can change bom",
  DELETE_BOM: "Can delete bom",
  VIEW_BOM: "Can view bom",

  ADD_BOM_EXW: "Can add bom exw",
  EDIT_BOM_EXW: "Can change bom exw",
  DELETE_BOM_EXW: "Can delete bom exw",
  VIEW_BOM_EXW: "Can view bom exw",

  ADD_CO: "Can add co",
  EDIT_CO: "Can change co",
  DELETE_CO: "Can delete co",
  VIEW_CO: "Can view co",

  ADD_ECUS: "Can add ecus",
  EDIT_ECUS: "Can change ecus",
  DELETE_ECUS: "Can delete ecus",
  VIEW_ECUS: "Can view ecus",

  ADD_PURCHASE: "Can add purchase",
  EDIT_PURCHASE: "Can change purchase",
  DELETE_PURCHASE: "Can delete purchase",
  VIEW_PURCHASE: "Can view purchase",

  ADD_STATEMENT15: "Can add statement15",
  EDIT_STATEMENT15: "Can change statement15",
  DELETE_STATEMENT15: "Can delete statement15",
  VIEW_STATEMENT15: "Can view statement15",

  ADD_STATEMENT15A: "Can add statement15a",
  EDIT_STATEMENT15A: "Can change statement15a",
  DELETE_STATEMENT15A: "Can delete statement15a",
  VIEW_STATEMENT15A: "Can view statement15a",

  ADD_HEADERS: "Can add headers",
  EDIT_HEADERS: "Can change headers",
  DELETE_HEADERS: "Can delete headers",
  VIEW_HEADERS: "Can view headers",

  ADD_WAREHOUSE_IOB: "Can add warehouse iob",
  EDIT_WAREHOUSE_IOB: "Can change warehouse iob",
  DELETE_WAREHOUSE_IOB: "Can delete warehouse iob",
  VIEW_WAREHOUSE_IOB: "Can view warehouse iob",

  ADD_ACCOUNT_IOB: "Can add account iob",
  EDIT_ACCOUNT_IOB: "Can change account iob",
  DELETE_ACCOUNT_IOB: "Can delete account iob",
  VIEW_ACCOUNT_IOB: "Can view account iob",

  ADD_BANGKE_HISTORY: "Can add bangke history",
  EDIT_BANGKE_HISTORY: "Can change bangke history",
  DELETE_BANGKE_HISTORY: "Can delete bangke history",
  VIEW_BANGKE_HISTORY: "Can view bangke history",

  ADD_PURCHASE_DO: "Can add purchase do",
  EDIT_PURCHASE_DO: "Can change purchase do",
  DELETE_PURCHASE_DO: "Can delete purchase do",
  VIEW_PURCHASE_DO: "Can view purchase do",

  ADD_ACCOUNT_PHYSICAL_INVENTORY: "Can add account physical inventory",
  EDIT_ACCOUNT_PHYSICAL_INVENTORY: "Can change account physical inventory",
  DELETE_ACCOUNT_PHYSICAL_INVENTORY: "Can delete account physical inventory",
  VIEW_ACCOUNT_PHYSICAL_INVENTORY: "Can view account physical inventory",

  ADD_ACCOUNT_SELL: "Can add account sell",
  EDIT_ACCOUNT_SELL: "Can change account sell",
  DELETE_ACCOUNT_SELL: "Can delete account sell",
  VIEW_ACCOUNT_SELL: "Can view account sell",

  ADD_WAREHOUSE_PHYSICAL_INVENTORY: "Can add warehouse physical inventory",
  EDIT_WAREHOUSE_PHYSICAL_INVENTORY: "Can change warehouse physical inventory",
  DELETE_WAREHOUSE_PHYSICAL_INVENTORY:
    "Can delete warehouse physical inventory",
  VIEW_WAREHOUSE_PHYSICAL_INVENTORY: "Can view warehouse physical inventory",

  ADD_CATEGORY_CODE: "Can add category code",
  EDIT_CATEGORY_CODE: "Can change category code",
  DELETE_CATEGORY_CODE: "Can delete category code",
  VIEW_CATEGORY_CODE: "Can view category code",

  ADD_NATIONAL_CODE: "Can add national code",
  EDIT_NATIONAL_CODE: "Can change national code",
  DELETE_NATIONAL_CODE: "Can delete national code",
  VIEW_NATIONAL_CODE: "Can view national code",

  ADD_PARTNER: "Can add partner",
  EDIT_PARTNER: "Can change partner",
  DELETE_PARTNER: "Can delete partner",
  VIEW_PARTNER: "Can view partner",

  ADD_BOM_CHANGE: "Can add bom change",
  EDIT_BOM_CHANGE: "Can change bom change",
  DELETE_BOM_CHANGE: "Can delete bom change",
  VIEW_BOM_CHANGE: "Can view bom change",

  ADD_COMMODITY: "Can add commodity",
  EDIT_COMMODITY: "Can change commodity",
  DELETE_COMMODITY: "Can delete commodity",
  VIEW_COMMODITY: "Can view commodity",

  ADD_DEDUCTION_HISTORY: "Can add deduction history",
  EDIT_DEDUCTION_HISTORY: "Can change deduction history",
  DELETE_DEDUCTION_HISTORY: "Can delete deduction history",
  VIEW_DEDUCTION_HISTORY: "Can view deduction history",

  ADD_FG_DIFFERENCE: "Can add fg difference",
  EDIT_FG_DIFFERENCE: "Can change fg difference",
  DELETE_FG_DIFFERENCE: "Can delete fg difference",
  VIEW_FG_DIFFERENCE: "Can view fg difference",

  ADD_HS_DIFFERENCE_PARTNER: "Can add hs difference partner",
  EDIT_HS_DIFFERENCE_PARTNER: "Can change hs difference partner",
  DELETE_HS_DIFFERENCE_PARTNER: "Can delete hs difference partner",
  VIEW_HS_DIFFERENCE_PARTNER: "Can view hs difference partner",

  ADD_HS_DIFFERENCE_YEAR: "Can add hs difference year",
  EDIT_HS_DIFFERENCE_YEAR: "Can change hs difference year",
  DELETE_HS_DIFFERENCE_YEAR: "Can delete hs difference year",
  VIEW_HS_DIFFERENCE_YEAR: "Can view hs difference year",

  ADD_INITIAL_BEGIN: "Can add initial begin",
  EDIT_INITIAL_BEGIN: "Can change initial begin",
  DELETE_INITIAL_BEGIN: "Can delete initial begin",
  VIEW_INITIAL_BEGIN: "Can view initial begin",

  ADD_INITIAL_ECUS: "Can add initial ecus",
  EDIT_INITIAL_ECUS: "Can change initial ecus",
  DELETE_INITIAL_ECUS: "Can delete initial ecus",
  VIEW_INITIAL_ECUS: "Can view initial ecus",

  ADD_INITIAL_PURCHASE: "Can add initial purchase",
  EDIT_INITIAL_PURCHASE: "Can change initial purchase",
  DELETE_INITIAL_PURCHASE: "Can delete initial purchase",
  VIEW_INITIAL_PURCHASE: "Can view initial purchase",

  ADD_PRODUCTION_PROCESS: "Can add production process",
  EDIT_PRODUCTION_PROCESS: "Can change production process",
  DELETE_PRODUCTION_PROCESS: "Can delete production process",
  VIEW_PRODUCTION_PROCESS: "Can view production process",

  ADD_PSR: "Can add psr",
  EDIT_PSR: "Can change psr",
  DELETE_PSR: "Can delete psr",
  VIEW_PSR: "Can view psr",

  ADD_OTHER_OUTPUT: "Can add account other output",
  EDIT_OTHER_OUTPUT: "Can change account other output",
  DELETE_OTHER_OUTPUT: "Can delete account other output",
  VIEW_OTHER_OUTPUT: "Can view account other output",

  ADD_DESTROY: "Can add destroy",
  EDIT_DESTROY: "Can change destroy",
  DELETE_DESTROY: "Can delete destroy",
  VIEW_DESTROY: "Can view destroy",
};

export const GenderEnum = {
  MALE: "Nam",
  FEMALE: "Nữ",
  OTHER: "Khác",
};

export const GENDERS = [
  {
    id: 0,
    name: GenderEnum.MALE,
    key: "MALE",
  },
  {
    id: 1,
    name: GenderEnum.FEMALE,
    key: "FEMALE",
  },
  {
    id: 2,
    name: GenderEnum.OTHER,
    key: "OTHER",
  },
];

export const UNIT_TYPES = ["PERCENT", "MONEY"];
export const COUPON_TYPES_ENUM = {
  QUANTITY: "QUANTITY",
  CREDIT: "CREDIT",
};

export const AppSearchColumn = {
  DATETIME: "DATE_TIME",
  INPUT_RANGE: "INPUT_RANGE",
  LIST_ITEM: "LIST_ITEM",
  INPUT_NUMBER: "INPUT_NUMBER",
};

export const TIME_RANGE_FILER_ENUM = {
  THIS_WEEK: "THIS_WEEK",
  LAST_WEEK: "LAST_WEEK",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  THIS_YEAR: "THIS_YEAR",
  LAST_YEAR: "LAST_YEAR",
  CUSTOM: "CUSTOM",
};

export const PAYMENT_METHOD_TYPE = [
  {
    name: "Authorize.Net",
    value: "Authorize.Net",
  },
  {
    name: "Cheque",
    value: "Cheque",
  },
  {
    name: "Zelle",
    value: "Zelle",
  },
  {
    name: "External Terminal",
    value: "External Terminal",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export const APP_FORM_MODAL_TYPE = {
  DEFAULT: "DEFAULT",
  TAB: "TAB",
};

export const CARD_FORM_ITEM_STATUS = {
  CREATE: "CREATE",
  PREVIEW: "PREVIEW",
  VIEW: "VIEW",
  CHANGE_CARD: "CHANGE_CARD",
};

export const AppAnimates = {
  SLIDEUPIN: {
    hidden: {
      y: "100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.2,
      },
    },
  },
  SLIDEUPOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNIN: {
    hidden: {
      opacity: 0,
      y: "-100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "-100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTIN: {
    hidden: {
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTIN: {
    hidden: {
      x: "-100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "-100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  FADEIN: {
    hidden: {
      opacity: 0,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeIn",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
  FADEOUT: {
    hidden: {
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 0,
      scale: 1,
      transition: {
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
};

export const USER_PROFILE_AVATAR_ACTION = {
  NONE: "NONE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const MANAGE_PRODUCT_STOCK_FIELD_TYPE = {
  NUMBER: "NUMBER",
  IMAGE: "IMAGE",
  CHECKBOX: "CHECKBOX",
};
