import { CheckCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import AppCustomDrawerWrapper from "@uni/core/AppCustomDrawerWraper";
import { Col, message, Modal, Row } from "antd";
import Button from "antd/es/button/button";
import usePostDeduction from "hooks/apis/bangke/usePostDeduction";
import usePostRollback from "hooks/apis/bangke/usePostRollback";
import {
  forwardRef,
  lazy,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import AppButtonActions from "@uni/core/AppButtonAction";

const Bangke = lazy(() =>
  import("pages/determination/Determine/GenerateModal/BangKe")
);
import * as XLSX from "xlsx-js-style";
import { PERMISSIONS } from "shared/constants/AppEnums";
import { useIntl } from "react-intl";

const transformDataDownload = (data) => {
  return data?.map((item, index) => [
    {
      v: index + 1,
      t: "n",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    },
    item.item_name,
    item.hs_code,
    item.unit,
    "",
    item.unit_price.toFixed(3),
    item.origin_price.toFixed(3),
    item.non_origin_price.toFixed(3),
    item.from_country,
    "",
    item.cd_date,
    item.exporter,
    item.issued_date,
  ]);
};

const ViewHistoryModal = forwardRef(({ data: DataBangke }, ref) => {
  const { messages } = useIntl();
  const [isVisible, setIsVisible] = useState(false);
  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);

  const closeModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );

  const ExportAllToExcel = () => {
    const workbook = XLSX.utils.book_new();

    const {
      bangke_data,
      recalculate_args,
      company_info,
      ecus_input,
      start_date,
    } = DataBangke || {};
    const itemMap =
      bangke_data?.length > 0 ? transformDataDownload(bangke_data) : [];
    const formular = (form_cateria, form_type, sedo) => {
      if (form_cateria === "RVC" && form_type === "EXW" && sedo === true) {
        return [
          [
            {
              v: "Trị giá giá gia công",
              t: "s",
              s: {
                alignment: {
                  horizontal: "right",
                },
              },
            },
            "",
            { v: "=", t: "s", s: { alignment: { horizontal: "center" } } },
            {
              v: "Số lượng         x        Giá gia công",
              t: "s",
              s: { alignment: { horizontal: "center" } },
            },
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            "",
            {
              v:
                ecus_input?.ecus_total_quantity +
                "           x                  " +
                recalculate_args?.progress_price,
              t: "s",
              s: { alignment: { horizontal: "center" } },
            },
            "",
            "",
            "=      " +
              Number(ecus_input?.ecus_total_quantity) *
                Number(recalculate_args?.progress_price),
          ],
          [
            {
              v: "Trị giá xuất xưởng",
              t: "s",
              s: { alignment: { horizontal: "right" } },
            },
            "",
            { v: "=", t: "s", s: { alignment: { horizontal: "center" } } },
            {
              v: "Trị giá NPL có xuất xứ + Trị giá NPL không xuất xứ + Trị giá giá gia công",
              t: "s",
              s: { alignment: { horizontal: "center" } },
            },
          ],
          [
            "",
            "",
            "",
            {
              v:
                recalculate_args?.total_origin_price?.toFixed(3) +
                "   +   " +
                recalculate_args?.total_non_origin_price?.toFixed(3) +
                "   +   " +
                Number(ecus_input?.ecus_total_quantity) *
                  Number(recalculate_args?.progress_price),
              t: "s",
              s: { alignment: { horizontal: "center" } },
            },
            "",
            "",
            {
              v:
                "=      " +
                (
                  recalculate_args?.total_origin_price +
                  recalculate_args?.total_non_origin_price +
                  Number(ecus_input?.ecus_total_quantity) *
                    Number(recalculate_args?.progress_price)
                ).toFixed(3),
              t: "s",
              s: { alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
          [
            {
              v: "Công thức % của giá trị nguyên liệu được sử dụng",
              t: "s",
              s: { alignment: { horizontal: "right" } },
            },
            "",
            { v: "=", t: "s", s: { alignment: { horizontal: "center" } } },
            {
              v: "Trị giá nguyên liệu đầu vào không có xuất xứ VN",
              t: "s",
              s: { alignment: { horizontal: "center" } },
            },
            "",
            "",
            "=      " + ecus_input?.formula_result?.toFixed(2) + "%",
          ],
          [
            "",
            "",
            "",
            {
              v: "Trị giá xuất xưởng",
              t: "s",
              s: { alignment: { horizontal: "center" } },
            },
          ],
        ];
      } else if (form_cateria === "RVC" && form_type === "EXW") {
        return [
          [
            "Công thức  =",
            "Tổng trị giá hàng hoá không xuất xứ",
            "x  100%   = ",
            ecus_input?.formula_result?.toFixed(2) + "%",
          ],
          ["", "Trị giá", "", ""],
        ];
      } else if (form_cateria === "RVC" && form_type === "FOB") {
        return [
          [
            "Công thức  =",
            "Trị giá - Tổng trị giá hàng hoá không xuất xứ",
            "x  100%   = ",
            ecus_input?.formula_result?.toFixed(2) + "%",
          ],
          ["", "Trị giá", "", ""],
        ];
      } else return [];
    };

    const data = [
      [
        {
          v: "PHỤ LỤC VIII",
          t: "s",
          s: {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: "center",
            },
          },
        },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
      ],
      [
        {
          v: "BẢNG KÊ KHAI HÀNG HOÁ XUẤT KHẨU ĐẠT TIÊU CHÍ CTC",
          t: "s",
          s: {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: "center",
            },
          },
        },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
      ],
      [
        {
          v: "(ban hành kèm theo Thông tư số: 05/2018/TT-BTC ngày 03 tháng 04 năm 2018 quy định về xuất xứ hàng hóa)",
          t: "s",
          s: {
            font: {
              italic: true,
            },
            alignment: {
              horizontal: "center",
            },
          },
        },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
      ],
      [
        {
          v: "Tên thương nhân: ",
          t: "s",
          s: { alignment: { wrapText: true } },
        },
        { v: company_info?.company_name, t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "Tiêu chí áp dụng: ", t: "s" },
        {
          v: recalculate_args?.criteria_value,
          t: "s",
          s: { alignment: { wrapText: true } },
        },
        { v: "", t: "s" },
        { v: "", t: "s" },
      ],
      [
        { v: "Địa chỉ: ", t: "s" },
        {
          v: company_info?.company_address,
          t: "s",
          s: { alignment: { wrapText: true } },
        },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "", t: "s" },
        { v: "Tên hàng hoá: ", t: "s" },
        {
          v: ecus_input?.ecus_item_name,
          t: "s",
          s: { alignment: { wrapText: true } },
        },
        { v: "", t: "s" },
        { v: "", t: "s" },
      ],
      [
        "Mã số thuế: ",
        company_info?.company_tax,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Mã HS của hàng hoá: ",
        ecus_input?.ecus_hs,
        "",
        "",
      ],
      [
        "Tờ khai số: ",
        `${ecus_input?.ecus_cd_number} ngày ${ecus_input?.ecus_cd_date}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Số lượng: ",
        {
          v: ecus_input?.ecus_total_quantity,
          t: "n",
          s: { alignment: { horizontal: "left" } },
        },
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Trị giá: ",
        {
          v: ecus_input?.formula_result,
          t: "n",
          s: {
            numberFormat: "#,##0",
          },
        },
        "",
        "",
      ],
      ["", "", "", "", "", "", "", "", "", "", "", "", ""],
      [],
      [
        {
          v: "STT",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Nguyên phụ liệu",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Mã HS",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Đơn vị tính",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "ĐM/1SP",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Nhu cầu nguyên liệu sử dụng cho lô hàng",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        { v: "", t: "s" },
        { v: "", t: "s" },
        {
          v: "Nước xuất xứ",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Tờ khai hải quan nhập khẩu/ Hoá đơn giá trị gia tăng",
          t: "s",
          s: {
            alignment: {
              horizontal: "center",
              vertical: "center",
              wrapText: true,
            },
          },
        },
        { v: "", t: "s" },
        {
          v: "C/O ưu đãi nhập khẩu/ Bản khai báo của nhà sản xuất/nhà cung cấp nguyên liệu trong nước",
          t: "s",
          s: {
            alignment: {
              horizontal: "center",
              vertical: "center",
              wrapText: true,
            },
          },
        },
        { v: "", t: "s" },
      ],
      [
        "",
        "",
        "",
        "",
        "",
        {
          v: "Đơn giá (CIF)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Trị giá USD",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "Có xuất xứ FTA",
        "Không xuất xứ FTA",
        "",
        {
          v: "Số",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Ngày",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Số",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "Ngày",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
      ],
      [
        {
          v: "(1)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(2)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(3)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(4)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(5)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(6)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(7)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(8)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(9)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(10)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(11)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(12)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
        {
          v: "(13)",
          t: "s",
          s: { alignment: { horizontal: "center", vertical: "center" } },
        },
      ],
      ...itemMap,
      [
        "Tổng",
        "",
        "",
        "",
        "",
        "",
        recalculate_args?.total_origin_price?.toFixed(3),
        recalculate_args?.total_non_origin_price?.toFixed(3),
        "",
        "",
        "",
        "",
        "",
      ],
      ...formular(
        recalculate_args?.form_criteria,
        recalculate_args?.form_type,
        recalculate_args?.progress_price !== null
      ),
      [
        "Kết luận: " +
          (ecus_input?.formula_status
            ? "Hàng hóa đáp ứng tiêu chí " + recalculate_args?.criteria_value
            : "Hàng hóa không đáp ứng tiêu chí " +
              recalculate_args?.criteria_value),
      ],
      [
        "Công ty cam kết số liệu khai trên là đúng và xin chịu trách nhiệm trước pháp luật về thông tin, số liệu đã khai.",
      ],
    ];

    const mergeFormular = (form_cateria, form_type, sedo) => {
      if (form_cateria === "RVC" && form_type === "EXW" && sedo === true) {
        return [
          { row: 15 + bangke_data?.length, col: 0, rowspan: 1, colspan: 2 },
          { row: 15 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 16 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 16 + bangke_data?.length, col: 0, rowspan: 1, colspan: 2 },
          { row: 16 + bangke_data?.length, col: 6, rowspan: 1, colspan: 2 },
          { row: 17 + bangke_data?.length, col: 0, rowspan: 1, colspan: 2 },
          { row: 17 + bangke_data?.length, col: 3, rowspan: 1, colspan: 5 },
          { row: 18 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 18 + bangke_data?.length, col: 6, rowspan: 1, colspan: 2 },
          { row: 18 + bangke_data?.length, col: 0, rowspan: 1, colspan: 2 },
          { row: 19 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 19 + bangke_data?.length, col: 0, rowspan: 2, colspan: 2 },
          { row: 19 + bangke_data?.length, col: 2, rowspan: 2, colspan: 1 },
          { row: 20 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 19 + bangke_data?.length, col: 6, rowspan: 2, colspan: 2 },
        ];
      } else if (form_cateria === "RVC" && form_type === "EXW") {
        return [
          { row: 15 + bangke_data?.length, col: 0, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length, col: 2, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length, col: 3, rowspan: 2, colspan: 1 },
        ];
      } else if (form_cateria === "RVC" && form_type === "FOB") {
        return [
          { row: 15 + bangke_data?.length, col: 0, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length, col: 2, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length, col: 3, rowspan: 2, colspan: 1 },
        ];
      } else return [];
    };
    const mergeCells = [
      { row: 0, col: 0, rowspan: 1, colspan: 13 },
      { row: 1, col: 0, rowspan: 1, colspan: 13 },
      { row: 2, col: 0, rowspan: 1, colspan: 13 },
      { row: 3, col: 10, rowspan: 1, colspan: 3 },
      { row: 4, col: 10, rowspan: 1, colspan: 3 },
      { row: 5, col: 10, rowspan: 1, colspan: 3 },
      { row: 6, col: 10, rowspan: 1, colspan: 3 },
      { row: 7, col: 10, rowspan: 1, colspan: 3 },
      { row: 9, col: 0, rowspan: 1, colspan: 13 },
      { row: 10, col: 0, rowspan: 3, colspan: 1 },
      { row: 10, col: 1, rowspan: 3, colspan: 1 },
      { row: 10, col: 2, rowspan: 3, colspan: 1 },
      { row: 10, col: 3, rowspan: 3, colspan: 1 },
      { row: 10, col: 4, rowspan: 3, colspan: 1 },
      { row: 10, col: 5, rowspan: 1, colspan: 3 },
      { row: 10, col: 8, rowspan: 3, colspan: 1 },
      { row: 10, col: 9, rowspan: 2, colspan: 2 },
      { row: 10, col: 11, rowspan: 2, colspan: 2 },
      { row: 11, col: 5, rowspan: 2, colspan: 1 },
      { row: 11, col: 6, rowspan: 1, colspan: 2 },
      // Add more merged cells as needed
      ...mergeFormular(
        recalculate_args?.form_criteria,
        recalculate_args?.form_type,
        recalculate_args?.progress_price !== null
      ),
      {
        row: data.length - 1,
        col: 0,
        rowspan: 1,
        colspan: 13,
      },
      {
        row: data.length - 2,
        col: 0,
        rowspan: 1,
        colspan: 13,
      },
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    worksheet["!merges"] = mergeCells.map((merge) => {
      return {
        s: { r: merge.row, c: merge.col },
        e: {
          r: merge.row + merge.rowspan - 1,
          c: merge.col + merge.colspan - 1,
        },
      };
    });
    const colWidths = [
      { width: 10 },
      { width: 40 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];

    worksheet["!cols"] = colWidths;

    const sheetName = `${ecus_input?.ecus_code}`;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(workbook, "BangkeExport.xlsx");
  };

  const { mutateAsync, isLoading } = usePostDeduction();
  const { mutateAsync: mutateRollback, isLoading: isLoadingRolback } =
    usePostRollback();

  const handleDeduction = async () => {
    await mutateAsync(DataBangke);
  };

  const handleRollback = async () => {
    await mutateRollback(DataBangke);
  };
  return (
    <Modal
      style={{ top: 44 }}
      open={isVisible}
      bodyStyle={{
        height: "85vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      onCancel={closeModal}
      footer={null}
      width={"90%"}
      maskClosable={true}
      title={messages["bangke.viewDetail"]}
      className="modal"
      destroyOnClose
    >
      <h3>{DataBangke?.ecus_input?.ecus_code}</h3>
      <div style={{ height: "100%" }}>
        <Bangke bangkeData={DataBangke} styleTable={{ height: "70vh" }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "8px",
          paddingTop: "24px",
          height: "100%",
          alignItems: "end",
        }}
      >
        <AppButtonActions
          scopes={[PERMISSIONS.EDIT_BANGKE_HISTORY]}
          title={messages["bangke.rollback"]}
          type="default"
          disabled={!DataBangke?.is_deducted}
          onClick={handleRollback}
        />

        <AppButtonActions
          scopes={[PERMISSIONS.EDIT_BANGKE_HISTORY]}
          title={messages["bangke.deduction"]}
          danger
          disabled={DataBangke?.is_deducted}
          onClick={handleDeduction}
        />

        <Button type="primary" onClick={ExportAllToExcel}>
          <DownloadOutlined />
          {messages["common.export"]}
        </Button>
      </div>
    </Modal>
  );
});

export default ViewHistoryModal;
