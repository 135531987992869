import React from "react";
import PropTypes from "prop-types";
import ButtonPermission from "../../core/AppPermission/ButtonPermission";

const AppButtonActions = (
  /** @type {{ onClick: () => void; disabled: boolean, title: string,type?:string,icon:any, danger?:boolean }} */ props
) => {
  return (
    <ButtonPermission
      scopes={props?.scopes}
      type={props?.type || "primary"}
      disabled={props?.disabled}
      style={{
        ...props.style,
        padding: "0px 10px",
      }}
      onClick={props?.onClick}
      icon={props?.icon}
      danger={props.danger}
    >
      {props.title}
    </ButtonPermission>
  );
};

AppButtonActions.propTypes = {
  scopes: PropTypes.array,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  icon: PropTypes.node,
  danger: PropTypes.bool,
};

AppButtonActions.defaultProps = {
  title: "Default Button",
  disabled: false,
  onClick: () => {},
  style: {},
  danger: false,
};

export default React.memo(AppButtonActions);
