import React from "react";
import "./index.style.less";
import PropTypes from "prop-types";
import { useSidebarContext } from "../../../../utility/AppContextProvider/SidebarContextProvider";
import { Link } from "react-router-dom";
import { PAGES_ROUTER } from "shared/constants/API";

const logo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="158"
    height="32"
    fill="none"
    viewBox="0 0 158 32"
  >
    <path
      fill="#092C4C" // Main color
      d="M0 27.694V5.306h15.888v4.897H5.985v3.848h9.085v4.898H5.985v3.848h9.86v4.897H0zM25.271 5.306l3.617 6.515h.172l3.66-6.515h6.674L32.806 16.5l6.846 11.194h-6.846l-3.746-6.646h-.172l-3.746 6.646H18.34L25.1 16.5 18.555 5.306h6.716zM47.96 5.306v22.388h-5.986V5.306h5.985zM50.795 5.306h7.45l5.123 12.68h.258l5.124-12.68h7.449v22.388h-5.856V14.75h-.172l-4.909 12.768h-3.53l-4.909-12.855h-.172v13.03h-5.856V5.306z"
    ></path>
    <path
      fill="#2F80ED"
      d="M77.657 27.694l3.66-22.388h5.985l-1.421 8.745h7.922l1.421-8.745h5.985l-3.66 22.388h-5.985l1.421-8.745h-7.922l-1.421 8.745h-5.985zM123.512 16.893c-.402 2.435-1.198 4.475-2.39 6.122-1.191 1.647-2.637 2.89-4.338 3.728A12.176 12.176 0 01111.327 28c-2.067 0-3.861-.456-5.382-1.366-1.521-.911-2.63-2.245-3.326-4.001-.696-1.757-.836-3.903-.42-6.44.388-2.433 1.177-4.481 2.368-6.143 1.192-1.661 2.645-2.918 4.36-3.771a12.186 12.186 0 015.5-1.279c2.053 0 3.836.46 5.35 1.377 1.521.919 2.626 2.263 3.315 4.034.696 1.771.836 3.932.42 6.482zm-6.028-.7c.201-1.282.215-2.36.043-3.235-.172-.882-.534-1.545-1.087-1.99-.553-.451-1.295-.677-2.228-.677-1.062 0-2.002.262-2.82.787-.818.525-1.497 1.279-2.035 2.263s-.915 2.168-1.13 3.553c-.23 1.297-.251 2.375-.065 3.235.194.86.575 1.505 1.141 1.935.575.43 1.321.645 2.239.645 1.048 0 1.974-.255 2.778-.765.81-.51 1.481-1.25 2.012-2.22.539-.968.923-2.145 1.152-3.53zM128.722 27.694L125.88 5.306h6.674l.775 13.774h.172l5.813-13.774h5.252l1.335 13.818h.172l5.253-13.818H158l-10.161 22.388h-5.727l-1.679-12.506h-.172l-5.813 12.506h-5.726zM32.333 16.217L25.798 5.219H17.63l6.535 10.998L16.813 28h8.168l7.352-11.783z"
    ></path>
  </svg>
);

const AppLogo = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <Link className="app-logo" to={PAGES_ROUTER.LOGIN}>
      {hasSidebarColor && sidebarColorSet.mode === "dark" ? logo : logo}
    </Link>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
