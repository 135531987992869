import { useEffect, useState } from "react";

const useDebounce = (value, delay) => {
  const [debouncing, setDebouncing] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    setDebouncing(true);
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      setDebouncing(false);
    }, delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return {
    debouncedValue,
    debouncing,
    setDebouncedValue,
  };
};

export default useDebounce;
