import {
  AutoComplete,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Rate,
  Switch,
  TimePicker,
  TreeSelect,
} from "antd";
import { AppInputType } from "../../../shared/constants/AppEnums";
import AppSingleImage from "../AppFileList/AppSingleImage";
import AppMultiImage from "../AppFileList/AppMultiImage";
import React from "react";
import ButtonPermission from "../AppPermission/ButtonPermission";
import AppSelectDisplayList from "../AppSelectDisplayList";
import SelectItem from "./AppFormItems/SelectItem";
import PhoneNumberInputItem from "./AppFormItems/PhoneNumberInputItem";
import TextEditorInput from "./AppFormItems/TextEditorInput";
import AppFileUpload from "../AppFileList/AppFileUpload";

const FieldItem = ({ fieldConfig = {} }) => {
  const form = Form.useFormInstance();
  const {
    inputConfig,
    formItemConfig,
    fieldCol,
    render,
    type,
    action,
    buttonName,
    scopes,
  } = fieldConfig;
  const data = form.getFieldsValue();
  const onMultipleImageRemove = (file) => {
    const id = file?.id;
    const name = formItemConfig?.name;
    if (id) {
      const existedDeletedIds = form.getFieldValue(`${name}DeletedIds`);
      form.setFieldValue(
        `${name}DeletedIds`,
        existedDeletedIds ? [...existedDeletedIds, id] : [id]
      );
    }
  };
  if (render) {
    return render(data, form);
  } else {
    switch (type) {
      case AppInputType.TEXT:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <Input {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.TEXT_AREA:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <Input.TextArea rows={5} value={data?.[name]} {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.NUMBER:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <InputNumber min={0} value={data?.[name]} {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.PASSWORD:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              hasFeedback
              {...formItemConfig}
            >
              <Input.Password
                value={data?.[name]}
                autoComplete="new-password"
                {...inputConfig}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.SELECT:
        return (
          <SelectItem
            formItemConfig={formItemConfig}
            inputConfig={inputConfig}
            fieldCol={fieldCol}
          />
        );
      case AppInputType.SELECT_DISPLAY_LIST:
        return (
          <SelectItem
            formItemConfig={formItemConfig}
            inputConfig={inputConfig}
            fieldCol={fieldCol}
            isDisplayList={true}
          />
        );
      case AppInputType.UPLOAD_SINGLE_IMAGE:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <AppSingleImage {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.UPLOAD_MULTI_IMAGE:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <AppMultiImage
                {...inputConfig}
                onRemove={onMultipleImageRemove}
              />
            </Form.Item>
            <Form.Item name={`${formItemConfig?.name}DeletedIds`} noStyle />
          </Col>
        );
      case AppInputType.FILE_UPLOAD:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <AppFileUpload
                {...inputConfig}
                onRemove={onMultipleImageRemove}
              />
            </Form.Item>
            <Form.Item name={`${formItemConfig?.name}DeletedIds`} noStyle />
          </Col>
        );
      case AppInputType.EDITOR:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 24 }}
              {...formItemConfig}
            >
              <TextEditorInput {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.CHECKBOX:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              valuePropName="checked"
              {...formItemConfig}
            >
              <Checkbox {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.DATE_PICKER:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <DatePicker
                className="date-picker"
                format={"DD/MM/YYYY"}
                showTime={false}
                {...inputConfig}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.TIME_PICKER:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <TimePicker {...inputConfig} className="time-picker" />
            </Form.Item>
          </Col>
        );
      case AppInputType.RATING:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <Rate {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.RADIO:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <Radio.Group {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.AUTO_COMPLETE:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <AutoComplete
                filterOption={(inputValue, option) =>
                  option.value
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) !== -1
                }
                {...inputConfig}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.BUTTON:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <ButtonPermission type="danger" onClick={action} scopes={scopes}>
                {buttonName}
              </ButtonPermission>
            </Form.Item>
          </Col>
        );
      case AppInputType.SWITCH:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              valuePropName="checked"
              {...formItemConfig}
            >
              <Switch {...inputConfig} />
            </Form.Item>
          </Col>
        );
      case AppInputType.TREE_SELECT:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <TreeSelect
                showSearch
                allowClear
                treeDefaultExpandAll
                treeNodeFilterProp="title"
                {...inputConfig}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.TREE_SELECT_DISPLAY_LIST:
        // eslint-disable-next-line no-case-declarations
        const { listItemRender, options, ...restInputConfigTreeSelect } =
          inputConfig ?? {};
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: 8 }}
              {...formItemConfig}
            >
              <AppSelectDisplayList
                listItemRender={listItemRender}
                options={options}
                customSelect={
                  <TreeSelect
                    showSearch
                    allowClear
                    treeDefaultExpandAll
                    treeNodeFilterProp="title"
                    options={options}
                    {...restInputConfigTreeSelect}
                  />
                }
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.PHONE_NUMBER_INPUT:
        return (
          <PhoneNumberInputItem
            inputConfig={inputConfig}
            fieldCol={fieldCol}
            formItemConfig={formItemConfig}
          />
        );
      default:
        return null;
    }
  }
};
export default FieldItem;
