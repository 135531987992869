import { Col, Empty, Form, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import AppSelectDisplayList from "../../AppSelectDisplayList";
import { filterByText } from "../../../utility/Utils";

export const withCustomOnchange = (Component) => (props) => {
  // use in clear select on edit form
  const { onChange } = props ?? {};
  const customOnChange = (value, option) => {
    if (value === undefined) onChange?.(null);
    else onChange?.(value, option);
  };
  return <Component {...props} onChange={customOnChange} />;
};

const SelectItem = ({
  inputConfig,
  formItemConfig,
  fieldCol,
  isDisplayList,
}) => {
  const form = Form.useFormInstance();
  const [initialOptions, setInitialOptions] = useState([]);
  const value = Form.useWatch(formItemConfig?.name);
  const {
    options = [],
    loadMoreItems,
    listItemRender,
    ...restInputConfig
  } = inputConfig;
  useEffect(() => {
    const isModeSingle = !inputConfig.mode;
    if (isModeSingle && typeof value === "object" && value) {
      const updatedInitialOptions = [value];
      setInitialOptions(updatedInitialOptions);
      form.setFieldValue(
        formItemConfig?.name,
        updatedInitialOptions.map(({ value }) => value)
      );
    }
    if (!isModeSingle && typeof value?.[0] === "object" && value?.[0]) {
      const updatedInitialOptions = [...value];
      setInitialOptions(updatedInitialOptions);
      form.setFieldValue(
        formItemConfig?.name,
        updatedInitialOptions.map(({ value }) => value)
      );
    }
  }, [value, inputConfig.mode, formItemConfig?.name, form]);

  const handledOptions = [
    ...new Map(
      [...initialOptions, ...options].map((item) => [item?.value, item])
    ).values(),
  ];
  const SelectComponent = ({ value, onChange }) => {
    return (
      <Select
        showSearch
        allowClear
        optionFilterProp="label"
        filterOption={(input, option) =>
          filterByText({ value: option?.label, input })
        }
        notFoundContent={
          inputConfig?.loading ? <Spin size="small" /> : <Empty />
        }
        onPopupScroll={(e) => {
          const { scrollTop, offsetHeight, scrollHeight } = e.target;
          const isReachingEnd = scrollTop + offsetHeight === scrollHeight;
          if (isReachingEnd) void loadMoreItems?.();
        }}
        options={handledOptions}
        value={value}
        onChange={onChange}
        {...restInputConfig}
      />
    );
  };

  const EnhancedSelectComponent = withCustomOnchange(SelectComponent);
  const EnhancedAppSelectDisplayList = withCustomOnchange(AppSelectDisplayList);
  return (
    <Col sm={fieldCol ?? 24}>
      <Form.Item labelAlign="left" labelCol={{ span: 8 }} {...formItemConfig}>
        {isDisplayList ? (
          <EnhancedAppSelectDisplayList
            listItemRender={listItemRender}
            customSelect={<SelectComponent />}
            options={handledOptions}
          />
        ) : (
          <EnhancedSelectComponent />
        )}
      </Form.Item>
    </Col>
  );
};

export default SelectItem;
