/* eslint-disable react/prop-types */
import { memo } from "react";
import { useAuthUser } from "../../utility/AuthHooks";
import { Button } from "antd";
import { checkPermissionGranted } from "../../utility/Utils";

const ButtonPermission = (props) => {
  const { user } = useAuthUser();
  const isGranted = checkPermissionGranted({
    permissions: user?.permissionRoles?.permission,
    scopes: props.scopes,
  });

  // prevent default submit with enter & space
  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
    }
  };

  return isGranted ? <Button {...props} onKeyDown={handleKeyDown} /> : <></>;
};

export default memo(ButtonPermission);
