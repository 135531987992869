import ComingSoon from "pages/errorPages/ComingSoon";

export const transactionConfig = [
  {
    path: "/transaction/material-price",
    element: <ComingSoon />,
  },
  {
    path: "/transaction/product-price",
    element: <ComingSoon />,
  },
  {
    path: "/transaction/inventory",
    element: <ComingSoon />,
  },
];
