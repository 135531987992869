import * as XLSX from "xlsx";

export const convertHiddenList = (addStartCell, addEndCell, sheetData) => {
  const colHidden = [];
  const rowHidden = [];
  for (let i = 0; i < sheetData?.length; i++) {
    if (i < addStartCell.row || i > addEndCell.row) {
      rowHidden.push(i);
    }
  }

  for (let j = 0; j < sheetData?.[0]?.length; j++) {
    if (j < addStartCell.col || j > addEndCell.col) {
      colHidden.push(j);
    }
  }

  return { colHidden, rowHidden };
};

export const convertShowData = (ws) => {
  const mergeCellsInfo = ws["!merges"] || [];
  const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 });

  const range = XLSX.utils?.decode_range(ws["!ref"]);
  let startColumn = range.s.c; // row start (zero-based index)
  let startRow = range.s.r; // col start (zero-based index)

  // handle merge cell
  const handsontableMergeCells = mergeCellsInfo.map((cell) => ({
    row: cell.s.r,
    col: cell.s.c,
    rowspan: cell.e.r - cell.s.r + 1,
    colspan: cell.e.c - cell.s.c + 1,
  }));

  const validMergeCells = handsontableMergeCells.filter((cell) => {
    return (
      cell.row + cell.rowspan <= jsonData.length &&
      cell.col + cell.colspan <= (jsonData[0] ? jsonData[0].length + 1 : 0)
    );
  });

  // change number of column when column not start at A
  if (startColumn > 0) {
    jsonData.map((row) => {
      for (let i = 0; i < startColumn; i++) row.unshift("");
      return row;
    });
  }

  // change length of frist array column
  const lengthOfArrayCol = [...jsonData]
    .filter((_, index) => index < 50)
    .map((row) => row.length);
  const maxLengthCol = Math.max(...lengthOfArrayCol);

  const firstRow = jsonData[0];

  const arrayFillNull = new Array(maxLengthCol).fill("");

  jsonData[0] = arrayFillNull.map((_, index) => firstRow[index] || "");

  // change number of row when row not start at 0
  if (startRow > 0) {
    for (let i = 0; i < startRow; i++) jsonData.unshift([...arrayFillNull]);
  }

  return { validMergeCells, jsonData };
};
