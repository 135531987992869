import IntlMessages from "@uni/utility/IntlMessages";
import { useIntl } from "react-intl";
import { COMMON_FORM_ITEM_RULE } from "shared/constants/AppConst";
import { AppInputType } from "shared/constants/AppEnums";

const useImportSelectSheetConfig = (options, onChange, valueSelect) => {
  const { messages } = useIntl();

  return [
    {
      type: AppInputType.SELECT,
      formItemConfig: {
        name: "sheet",
        label: <IntlMessages id="common.import.sheet" />,
        labelCol: 6,
        rules: [COMMON_FORM_ITEM_RULE.REQUIRED],
      },
      fieldCol: 6,
      inputConfig: {
        placeholder: messages["common.import.sheet.placeholder"],
        options,
        onChange: onChange,
        value: valueSelect,
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "start",
        label: <IntlMessages id="common.import.start" />,
        labelCol: 6,
        rules: [COMMON_FORM_ITEM_RULE.REQUIRED],
      },
      fieldCol: 6,
      inputConfig: {
        placeholder: messages["common.import.start.placeholder"],
        disabled: !valueSelect,
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "end",
        label: <IntlMessages id="common.import.end" />,
        labelCol: 6,
        rules: [COMMON_FORM_ITEM_RULE.REQUIRED],
      },
      fieldCol: 6,
      inputConfig: {
        placeholder: messages["common.import.end.placeholder"],
        disabled: !valueSelect,
      },
    },
  ];
};

export default useImportSelectSheetConfig;
