import { useIntl } from "react-intl";
import { AppInputType } from "shared/constants/AppEnums";

export const RULES_INPUT = {
  PASSWORD_CONFIRM: "Passwords do not match",
};

export const useProfileSettingConfig = () => {
  const { messages } = useIntl();
  return [
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "name",
        labelCol: { span: 4 },
        label: messages["app.setting.profile.name"],
      },
      fieldCol: 24,
      inputConfig: {
        placeholder: messages["app.setting.profile.name"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "email",
        // labelCol: { span: 24 },
        label: messages["app.setting.profile.email"],
      },
      fieldCol: 12,
      inputConfig: {
        disabled: true,
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "phone",
        // labelCol: { span: 24 },
        label: messages["app.setting.profile.phone"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.phone"],
      },
    },
    {
      type: AppInputType.DATE_PICKER,
      formItemConfig: {
        name: "birthday",
        // labelCol: { span: 4 },
        label: messages["app.setting.profile.birthday"],
        width: "100%",
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.birthday"],
        width: "100%",
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "position",
        // labelCol: { span: 4 },
        label: messages["app.setting.profile.position"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.position"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "address",
        labelCol: { span: 4 },
        label: messages["app.setting.profile.address"],
      },
      fieldCol: 24,
      inputConfig: {
        placeholder: messages["app.setting.profile.address"],
      },
    },
    {
      type: AppInputType.TEXT_AREA,
      formItemConfig: {
        name: "bio",
        labelCol: { span: 4 },
        label: messages["app.setting.profile.biography"],
      },
      fieldCol: 24,
      inputConfig: {
        placeholder: messages["app.setting.profile.biography"],
      },
    },
  ];
};

export const useCompanySettingConfig = () => {
  const { messages } = useIntl();

  return [
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "name",
        // labelCol: { span: 4 },
        rules: [
          {
            required: true,
            message: `${messages["app.setting.profile.name"]} ${messages["common.form.isRequired"]}`,
          },
        ],
        label: messages["app.setting.profile.name"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.name"],
        disabled: true,
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "tax_code",
        // labelCol: { span: 4 },
        rules: [
          {
            required: true,
            message: `${messages["app.setting.profile.taxCode"]} ${messages["common.form.isRequired"]}`,
          },
        ],
        label: messages["app.setting.profile.taxCode"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.taxCode"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "country",
        // labelCol: { span: 4 },
        rules: [
          {
            required: true,
            message: `${messages["app.setting.profile.country"]} ${messages["common.form.isRequired"]}`,
          },
        ],
        label: messages["app.setting.profile.country"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.country"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "address",
        // labelCol: { span: 4 },
        rules: [
          {
            required: true,
            message: `${messages["app.setting.profile.address"]} ${messages["common.form.isRequired"]}`,
          },
        ],
        label: messages["app.setting.profile.address"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.address"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "email",
        // labelCol: { span: 4 },
        label: messages["app.setting.profile.email"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.email"],
        type: "email",
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "phone_number",
        // labelCol: { span: 4 },
        label: messages["app.setting.profile.contact"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.contact"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "representative_name",
        labelCol: { span: 4 },
        label: messages["app.setting.profile.representativeName"],
      },
      fieldCol: 24,
      inputConfig: {
        placeholder: messages["app.setting.profile.representativeName"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "representative_email",
        // labelCol: { span: 4 },
        label: messages["app.setting.profile.representativeEmail"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.representativeEmail"],
        type: "email",
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "representative_contact",
        // labelCol: { span: 4 },
        label: messages["app.setting.profile.representativeContact"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.representativeContact"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "pic_name",
        // labelCol: { span: 4 },
        label: messages["app.setting.profile.picName"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.picName"],
      },
    },
    {
      type: AppInputType.TEXT,
      formItemConfig: {
        name: "pic_contact",
        // labelCol: { span: 4 },
        label: messages["app.setting.profile.picContact"],
      },
      fieldCol: 12,
      inputConfig: {
        placeholder: messages["app.setting.profile.picContact"],
      },
    },
  ];
};

export const useSecuritySettingConfig = () => {
  const { messages } = useIntl();

  return [
    {
      type: AppInputType.PASSWORD,
      formItemConfig: {
        name: "password",
        labelCol: { span: 4 },
        label: messages["app.setting.profile.password"],
        rules: [
          {
            required: true,
            message: `${messages["app.setting.profile.password"]} ${messages["common.form.isRequired"]}`,
          },
        ],
      },
      fieldCol: 24,
      inputConfig: {
        placeholder: messages["app.setting.profile.password"],
      },
    },
    {
      type: AppInputType.PASSWORD,
      formItemConfig: {
        name: "confirm-password",
        labelCol: { span: 4 },
        label: messages["app.setting.profile.passwordConfirm"],
        rules: [
          {
            required: true,
            message: `${messages["app.setting.profile.passwordConfirm"]} ${messages["common.form.isRequired"]}`,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(RULES_INPUT.PASSWORD_CONFIRM));
            },
          }),
        ],
      },
      fieldCol: 24,
      inputConfig: {
        placeholder: messages["app.setting.profile.passwordConfirm"],
      },
    },
  ];
};
