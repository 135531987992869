import { InfoCircleOutlined } from "@ant-design/icons";
import AppColumnActionOption from "@uni/core/AppColumnActionOption";
import IntlMessages from "@uni/utility/IntlMessages";
import { Tooltip } from "antd";
import { useRef } from "react";
import ViewHistoryModal from "./ViewHistoryModal";
import AppCustomDrawerWrapper from "@uni/core/AppCustomDrawerWraper";
import AppCustomModalWrapper from "@uni/core/AppCustomModalWrapper";

const HistoryColumnActionOption = ({ record }) => {
  const viewHistoryModalRef = useRef();

  return (
    <>
      <AppColumnActionOption
        items={[
          {
            // scopes: [PERMISSIONS.EDIT_PROJECT],
            label: (
              <Tooltip
                placement="top"
                title={<IntlMessages id="bangke.viewDetail" />}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => viewHistoryModalRef?.current?.openModal()}
                >
                  <InfoCircleOutlined
                    style={{ fontSize: "20px", color: "#0A8FDC" }}
                  />
                </div>
              </Tooltip>
            ),
          },
        ]}
      />
      <AppCustomModalWrapper ref={viewHistoryModalRef}>
        <ViewHistoryModal data={record} />
      </AppCustomModalWrapper>
    </>
  );
};

export default HistoryColumnActionOption;
