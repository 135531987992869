import IntlMessages from "@uni/utility/IntlMessages";
import { convertDateUni } from "@uni/utility/Utils";

const useGetDetermineColumn = () => {
  return [
    {
      title: <IntlMessages id="determination.determine.invoiceNo" />,
      dataIndex: "invoice_no",
      key: "invoice_no",
      width: "auto",
    },
    {
      title: <IntlMessages id="determination.determine.invoiceDate" />,
      dataIndex: "invoice_date",
      key: "invoice_date",
      width: "auto",
      render: (data) => convertDateUni(data),
    },
  ];
};

export default useGetDetermineColumn;
