import { message } from "antd";
import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { errorHandlerMessage } from "../../../utility/Utils";
import { URL_API } from "../../endpoint";
import jwtAxios, {
  genBodyFormUrlEncoded,
  genHeader,
  genHeaderLogin,
  setAuthToken,
} from "./jwt-api";

import { useNavigate } from "react-router-dom";
import {
  DEFAULT_CLIENT_ID,
  DEFAULT_CLIENT_SECRET,
  DEFAULT_GRANT_TYPE,
  PAGES_ROUTER,
  VERIFICATION_TYPE,
} from "shared/constants/API";
import { useLocaleActionsContext } from "@uni/utility/AppContextProvider/LocaleContextProvide";
import languageData from "@uni/core/AppLanguageSwitcher/data";

const JWTAuthContext = createContext(null);
const JWTAuthActionsContext = createContext(null);

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { updateLocale } = useLocaleActionsContext();

  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  // handle Sign in user
  const signInUser = useCallback(
    async ({ username, password, remember }) => {
      try {
        const loginData = genBodyFormUrlEncoded({
          username,
          password,
          grant_type: DEFAULT_GRANT_TYPE,
          client_id: DEFAULT_CLIENT_ID,
          client_secret: DEFAULT_CLIENT_SECRET,
        });

        const data = await jwtAxios.post(
          URL_API.AUTHENTICATION.LOGIN,
          loginData,
          {
            ...genHeaderLogin(),
          }
        );

        const dataUser = await jwtAxios.get(
          URL_API.PROFILE.GET_USER_PROFILE,
          genHeader(data.access_token)
        );

        const dataPermissions = await jwtAxios.get(
          URL_API.PROFILE.GET_USER_PERMISSIONS,
          genHeader(data.access_token)
        );

        setAuthToken(data.access_token, remember);

        message.success(`Welcome back ${dataUser?.name || ""}`);

        setJWTAuthData({
          ...firebaseData,
          user: {
            ...dataUser,
            permissionRoles: dataPermissions,
            role: { name: dataUser?.position },
          },
          isAuthenticated: true,
          isLoading: false,
        });
      } catch (error) {
        setJWTAuthData({
          ...firebaseData,
          isAuthenticated: false,
          isLoading: false,
        });
        console.log("error", error);
        message.error(error?.detail);
      }
    },
    [firebaseData]
  );

  // handle Sign up user
  const signUpUser = useCallback(async ({ username, email, password }) => {
    try {
      const data = await jwtAxios.post(URL_API.AUTHENTICATION.REGISTRATION, {
        username,
        email,
        password,
      });

      const { status } = data;
      if (status) {
        navigate(PAGES_ROUTER.VERIFY, {
          state: {
            email: email,
            nextPage: PAGES_ROUTER.LOGIN,
            type: VERIFICATION_TYPE.REGISTER,
          },
        });
      }
    } catch (error) {
      console.log("error", error);
      const msg = errorHandlerMessage(error);
      message.error(msg);
    }
  }, []);

  // handle Reset password user
  const resetPasswordUser = useCallback(async ({ email }) => {
    try {
      const data = await jwtAxios.post(URL_API.AUTHENTICATION.PASSWORD_RESET, {
        email,
      });

      const { status } = data;
      if (status) {
        navigate(PAGES_ROUTER.VERIFY, {
          state: {
            email: email,
            nextPage: PAGES_ROUTER.CHANGE_PASSWORD,
            type: VERIFICATION_TYPE.FORGOT,
          },
        });
      }
    } catch (error) {
      console.log("error", error);
      const msg = errorHandlerMessage(error);
      message.error(msg);
    }
  }, []);

  // handle Forgot password user
  const forgotChangePasswordUser = useCallback(async ({ password, token }) => {
    try {
      await jwtAxios.post(URL_API.AUTHENTICATION.PASSWORD_RESET_CONFIRM, {
        password,
        token,
      });
      message.success("Password has been changed");
      setAuthToken();
      navigate(PAGES_ROUTER.LOGIN);
    } catch (error) {
      console.log("error", error);
      const msg = errorHandlerMessage(error);
      message.error(msg);
      return msg;
    }
  }, []);

  // handle Logout user
  const logout = useCallback(async () => {
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });

    // reset language
    localStorage.removeItem("locale");
    updateLocale(languageData[0]);
  }, []);

  // handle Verify user
  const verifyUser = useCallback(async ({ otp, to, type, email }) => {
    const parseOtpNumber = Number(otp);
    try {
      switch (type) {
        case VERIFICATION_TYPE.REGISTER:
          await jwtAxios.post(URL_API.AUTHENTICATION.REGISTRATION_CONFIRM, {
            token: parseOtpNumber,
          });
          message.success("Sign Up Successfully");
          navigate(to);
          break;
        case VERIFICATION_TYPE.FORGOT:
          await jwtAxios.post(URL_API.AUTHENTICATION.PASSWORD_RESET_VALIDATE, {
            token: parseOtpNumber,
            email,
          });
          navigate(to, {
            state: {
              token: parseOtpNumber,
            },
          });
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
      const msg = errorHandlerMessage(error);
      message.error(msg);
    }
  }, []);

  // handle Resend code
  const resendCode = useCallback(async ({ email, type }) => {
    try {
      switch (type) {
        case VERIFICATION_TYPE.FORGOT:
          await jwtAxios.post(URL_API.AUTHENTICATION.PASSWORD_RESET, {
            email,
          });
          break;
        case VERIFICATION_TYPE.REGISTER:
          await jwtAxios.post(URL_API.AUTHENTICATION.REGISTRATION_RESEND, {
            email,
          });
          break;

        default:
          break;
      }

      message.success("Resend code is sent");
    } catch (error) {
      console.log("error", error);
      const msg = errorHandlerMessage(error);
      message.error(msg);
    }
  }, []);

  // handle Change password user
  const changePasswordUser = useCallback(async ({ password }) => {
    try {
      await jwtAxios.put(URL_API.AUTHENTICATION.PASSWORD_CHANGE, {
        new_password: password,
      });
      message.success("Password has been changed");
      setAuthToken();
      navigate(0);
    } catch (error) {
      console.log("error", error);
      const msg = errorHandlerMessage(error);
      message.error(msg);
      return msg;
    }
  }, []);

  useEffect(() => {
    const getAuthUser = async () => {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get(URL_API.PROFILE.GET_USER_PROFILE, genHeader(token))
        .then((data) => {
          if (data) {
            jwtAxios
              .get(URL_API.PROFILE.GET_USER_PERMISSIONS, genHeader(token))
              .then((dataPermissions) => {
                setJWTAuthData({
                  user: {
                    ...data,
                    permissionRoles: dataPermissions,
                    role: { name: data?.position },
                  },
                  isLoading: false,
                  isAuthenticated: true,
                });
              });
          } else {
            throw new Error("User not found");
          }
        })
        .catch(() => {
          setAuthToken();
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          });
        });
    };
    getAuthUser();
  }, []);

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          resetPasswordUser,
          forgotChangePasswordUser,
          verifyUser,
          resendCode,
          changePasswordUser,
          setJWTAuthData,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
