// @ts-nocheck
import React from "react";
import { PERMISSIONS } from "shared/constants/AppEnums";

const Dashboard = React.lazy(() => import("./Dashboard"));

export const dashboardConfig = [
  {
    path: "/dashboards",
    element: <Dashboard />,
    scopes: [PERMISSIONS.VIEW_DASHBOARD],
  },
];
