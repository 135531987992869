import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getTaxRisk = async (params) => {
  const res = await jwtAxios.get(URL_API.TAX_RISK.GET_ALL, { params });
  return res;
};

const useGetAllTaxRisk = (params) =>
  useQuery([URL_API.TAX_RISK.GET_ALL, params], () => getTaxRisk(params), {
    enabled: !!params?.folder_slug && !!params?.company_slug,
  });

export default useGetAllTaxRisk;
