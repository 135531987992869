import { S3Type } from "../../../shared/constants/AppEnums";
import { convertToS3Link } from "../Utils";

export const getUserFromJwtAuth = (user) => {
  const linkedAgent = user?.linkedAgent;
  const permissions = user?.permissionRoles?.permission
    ?.filter((permissionRole) => !permissionRole.deletedAt)
    .map((permissionRole) => permissionRole?.permission?.name);
  const photoURL = convertToS3Link(
    S3Type.UID_AVATAR,
    user?.id,
    user?.s3Profile
  );
  return {
    ...user,
    photoURL,
    permissions: permissions,
    linkedAgent: linkedAgent?.deletedAt ? null : linkedAgent,
    linkedAgentId: linkedAgent?.deletedAt ? null : linkedAgent?.id,
  };
};
