import { useCallback, useMemo, useState } from "react";
import { Modal, Upload } from "antd";
import { AiOutlinePlus } from "react-icons/ai";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AppMultiImage = ({ value, onChange, onRemove, className }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const handleCancel = useCallback(() => setPreviewOpen(false), []);

  const handlePreview = useCallback(async (file) => {
    if (file.url) {
      setPreviewImage(file.url);
      setPreviewOpen(true);
    } else {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.preview);
      setPreviewOpen(true);
    }
  }, []);
  const uploadProps = useMemo(
    () => ({
      listType: "picture-card",
      accept: "image/*",
      fileList: Array.isArray(value) ? value : [],
      multiple: true,
      onPreview: handlePreview,
      className,
      onRemove,
      onChange: ({ fileList }) => {
        onChange(fileList);
      },

      customRequest: ({ onSuccess }) => {
        setTimeout(() => {
          onSuccess?.("ok");
        }, 0);
      },
    }),
    [className, handlePreview, onChange, onRemove, value]
  );
  return useMemo(() => {
    return (
      <>
        <Upload {...uploadProps}>
          <AiOutlinePlus />
        </Upload>
        <Modal
          open={previewOpen}
          title="Preview Image"
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </>
    );
  }, [handleCancel, previewImage, previewOpen, uploadProps]);
};
export default AppMultiImage;

AppMultiImage.defaultProps = {
  maxCount: 1,
  value: [],
};
