import { dashboardConfig } from "./dashboard";
import { errorPagesConfigs } from "./errorPages";
import { authRouteConfig } from "./auth";
import { initialUrl } from "../shared/constants/AppConst";
import { profileConfig } from "./profile";
import ComponentCore from "./ComponentCore/ComponentCore";
import Error403 from "./errorPages/Error403";
import Error404 from "./errorPages/Error404";
import { systemConfig } from "./system";
import { dataConfig } from "./data";
import { ftaConfig } from "./fta";
import { deterConfig } from "./determination";
import { masterConfig } from "./master";
import { transactionConfig } from "./Transaction";
import { companyConfig } from "./company";
import { balanceConfig } from "./balance";

const authorizedStructure = {
  fallbackPath: "/login",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...dashboardConfig,
    ...profileConfig,
    ...systemConfig,
    ...dataConfig,
    ...ftaConfig,
    ...deterConfig,
    ...masterConfig,
    ...transactionConfig,
    ...companyConfig,
    ...balanceConfig,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: "/component-core",
      element: <ComponentCore />,
    },
    {
      path: "*",
      exact: true,
      element: <Error404 />,
    },
  ]),
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
