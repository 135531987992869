export const URL_API = {
  AUTHENTICATION: {
    LOGIN: "auth/login/",
    REGISTRATION: "auth/registration/",
    REGISTRATION_RESEND: "auth/registration/resend/",
    REGISTRATION_CONFIRM: "auth/registration/confirm/",
    PASSWORD_RESET: "auth/password-reset/",
    PASSWORD_RESET_VALIDATE: "auth/password-reset/validate-token/",
    PASSWORD_RESET_CONFIRM: "auth/password-reset/confirm/",
    PASSWORD_CHANGE: "auth/change-password/",
  },
  ECUS: {
    GET_ALL: "api/raw/ecus/",
    GET_ONE: "api/raw/ecus/",
    CREATE: "api/raw/ecus/",
    UPDATE: "api/raw/ecus/",
    DELETE: "api/raw/ecus/",
    DELETE_BULK: "api/raw/ecus/bulk-delete/",
    GET_ALL_INVOICE: "api/ecus/invoice/",
    GET_ONE_INVOICE: "api/ecus/invoice/",
    IMPORT: "api/raw/ecus/import/",
    GET_HEADER: "api/raw/ecus/headers/",
    SAVE_HEADER: "api/raw/ecus/setup_headers/",
  },
  STATEMENT15A: {
    GET_ALL: "api/statement15A/",
    GET_ONE: "api/statement15A/",
    CREATE: "api/statement15A/",
    UPDATE: "api/statement15A/",
    DELETE: "api/statement15A/",
    DELETE_BULK: "api/statement15A/bulk-delete/",
    IMPORT: "api/statement15A/import/",
    GET_HEADER: "api/statement15A/headers/",
    SAVE_HEADER: "api/statement15A/setup_headers/",
  },
  PURCHASE: {
    GET_ALL: "api/raw/purchase/",
    GET_ONE: "api/raw/purchase/",
    CREATE: "api/raw/purchase/",
    UPDATE: "api/raw/purchase/",
    DELETE: "api/raw/purchase/",
    DELETE_BULK: "api/raw/purchase/bulk-delete/",
    IMPORT: "api/raw/purchase/import/",
    GET_HEADER: "api/raw/purchase/headers/",
    SAVE_HEADER: "api/raw/purchase/setup_headers/",
  },
  PURCHASE_DO: {
    GET_ALL: "api/purchase_do/",
    GET_ONE: "api/purchase_do/",
    CREATE: "api/purchase_do/",
    UPDATE: "api/purchase_do/",
    DELETE: "api/purchase_do/",
    DELETE_BULK: "api/purchase_do/bulk-delete/",
    IMPORT: "api/purchase_do/import/",
    GET_HEADER: "api/purchase_do/headers/",
    SAVE_HEADER: "api/purchase_do/setup_headers/",
  },
  STATEMENT15: {
    GET_ALL: "api/statement15/",
    GET_ONE: "api/statement15/",
    CREATE: "api/statement15/",
    UPDATE: "api/statement15/",
    DELETE: "api/statement15/",
    DELETE_BULK: "api/statement15/bulk-delete/",
    IMPORT: "api/statement15/import/",
    GET_HEADER: "api/statement15/headers/",
    SAVE_HEADER: "api/statement15/setup_headers/",
  },
  BOM: {
    GET_ALL: "api/bom/",
    GET_ONE: "api/bom/",
    CREATE: "api/bom/",
    UPDATE: "api/bom/",
    DELETE: "api/bom/",
    DELETE_BULK: "api/bom/bulk-delete/",
    IMPORT: "api/bom/import/",
    GET_HEADER: "api/bom/headers/",
    SAVE_HEADER: "api/bom/setup_headers/",
    FIX: "api/bom/fix/",
  },
  BOM_EXW: {
    GET_ALL: "api/bom-exw/",
    GET_ONE: "api/bom-exw/",
    CREATE: "api/bom-exw/",
    UPDATE: "api/bom-exw/",
    DELETE: "api/bom-exw/",
    DELETE_BULK: "api/bom-exw/bulk-delete/",
    IMPORT: "api/bom-exw/import/",
    GET_HEADER: "api/bom-exw/headers/",
    SAVE_HEADER: "api/bom-exw/setup_headers/",
    FIX: "api/bom-exw/fix/",
  },
  ANNEX: {
    GET_ALL: "api/annex/",
    GET_ONE: "api/annex/",
    CREATE: "api/annex/",
    UPDATE: "api/annex/",
    DELETE: "api/annex/",
    DELETE_BULK: "api/annex/bulk-delete/",
    IMPORT: "api/annex/import/",
    GET_HEADER: "api/annex/headers/",
    SAVE_HEADER: "api/annex/setup_headers/",
  },
  BEGIN: {
    GET_ALL: "api/raw/begin/",
    GET_ONE: "api/raw/begin/",
    CREATE: "api/raw/begin/",
    UPDATE: "api/raw/begin/",
    DELETE: "api/raw/begin/",
    DELETE_BULK: "api/raw/begin/bulk-delete/",
    IMPORT: "api/raw/begin/import/",
    GET_HEADER: "api/raw/begin/headers/",
    SAVE_HEADER: "api/raw/begin/setup_headers/",
  },
  CO_LIST: {
    GET_ALL: "api/co/",
    GET_ONE: "api/co/",
    CREATE: "api/co/",
    UPDATE: "api/co/",
    DELETE: "api/co/",
    DELETE_BULK: "api/co/bulk-delete/",
    IMPORT: "api/co/import/",
    GET_HEADER: "api/co/headers/",
    SAVE_HEADER: "api/co/setup_headers/",
  },
  PROFILE: {
    GET_USER_PROFILE: "info/profile/",
    PUT_USER_PROFILE: "info/profile",
    GET_USER_PERMISSIONS: "info/users/user-permission/",
  },
  COMPANY: {
    GET_USER_COMPANY: "api/companies/",
    PUT_USER_COMPANY: "api/companies",
    COMPANY_MANAGEMENT: "api/company-managements/",
  },
  PARTNER: {
    GET_ALL: "api/master/partner/",
    GET_BY_ID: "api/master/partner/",
    UPDATE: "api/master/partner/",
    CREATE: "api/master/partner/",
    DELETE_BULK: "api/master/partner/bulk-delete/",
    IMPORT: "api/master/partner/import/",
    GET_HEADER: "api/master/partner/headers/",
    SAVE_HEADER: "api/master/partner/setup_headers/",
  },
  COMMODITY: {
    GET_ALL: "api/master/commodity/",
    GET_BY_ID: "api/master/commodity/",
    UPDATE: "api/master/commodity/",
    CREATE: "api/master/commodity/",
    DELETE_BULK: "api/master/commodity/bulk-delete/",
    IMPORT: "api/master/commodity/import/",
    GET_HEADER: "api/master/commodity/headers/",
    SAVE_HEADER: "api/master/commodity/setup_headers/",
  },
  BOM_CHANGE: {
    GET_ALL: "api/master/bom_change/",
    GET_BY_ID: "api/master/bom_change/",
    UPDATE: "api/master/bom_change/",
    CREATE: "api/master/bom_change/",
    DELETE_BULK: "api/master/bom_change/bulk-delete/",
    IMPORT: "api/master/bom_change/import/",
    GET_HEADER: "api/master/bom_change/headers/",
    SAVE_HEADER: "api/master/bom_change/setup_headers/",
  },
  HS_YEAR: {
    GET_ALL: "api/master/hs_diff_year/",
    GET_BY_ID: "api/master/hs_diff_year/",
    UPDATE: "api/master/hs_diff_year/",
    CREATE: "api/master/hs_diff_year/",
    DELETE_BULK: "api/master/hs_diff_year/bulk-delete/",
    IMPORT: "api/master/hs_diff_year/import/",
    GET_HEADER: "api/master/hs_diff_year/headers/",
    SAVE_HEADER: "api/master/hs_diff_year/setup_headers/",
  },
  HS_PARTNER: {
    GET_ALL: "api/master/hs_diff_partner/",
    GET_BY_ID: "api/master/hs_diff_partner/",
    UPDATE: "api/master/hs_diff_partner/",
    CREATE: "api/master/hs_diff_partner/",
    DELETE_BULK: "api/master/hs_diff_partner/bulk-delete/",
    IMPORT: "api/master/hs_diff_partner/import/",
    GET_HEADER: "api/master/hs_diff_partner/headers/",
    SAVE_HEADER: "api/master/hs_diff_partner/setup_headers/",
  },
  PRODUCTION_PROCESS: {
    GET_ALL: "api/master/production_process/",
    GET_BY_ID: "api/master/production_process/",
    UPDATE: "api/master/production_process/",
    CREATE: "api/master/production_process/",
    DELETE_BULK: "api/master/production_process/bulk-delete/",
    IMPORT: "api/master/production_process/import/",
    GET_HEADER: "api/master/production_process/headers/",
    SAVE_HEADER: "api/master/production_process/setup_headers/",
  },
  FG_DIFF: {
    GET_ALL: "api/master/fg_diff/",
    GET_BY_ID: "api/master/fg_diff/",
    UPDATE: "api/master/fg_diff/",
    CREATE: "api/master/fg_diff/",
    DELETE_BULK: "api/master/fg_diff/bulk-delete/",
    IMPORT: "api/master/fg_diff/import/",
    GET_HEADER: "api/master/fg_diff/headers/",
    SAVE_HEADER: "api/master/fg_diff/setup_headers/",
  },
  DEDUCTION_HISTORY: {
    GET_ALL: "api/history/deduction/",
  },
  BANG_KE: {
    DEFAULT: "api/logic/bangke_default/",
    CUSTOM: "api/logic/bangke_custom/",
    MANUAL_CUSTOM: "api/logic/bangke_manual/custom/",
    MANUAL_DEFAULT: "api/logic/bangke_manual/default/",
    RECALCULATE: "api/logic/bangke_recalculate/",
    SAVE: "api/history/bangke/",
  },
  UPLOAD: {
    DOWNLOAD_FORM: "api/file/download/",
    MERGE_FILE: "api/file/merge/",
  },
  CO_FORM: {
    GET_ALL: "api/co-form/",
    DELETE_ALL: "api/co-form/all/",
    IMPORT: "api/co-form/import/",
    GET_HEADER: "api/co-form/headers/",
    SAVE_HEADER: "api/co-form/setup_headers/",
  },
  HISTORY: {
    GET_ALL: "api/history/bangke/",
    DEDUCTION: "api/history/deduction/",
    ROLLBACK: "api/history/bangke/roll-back/",
  },
  IOB: {
    GET_ALL: "api/wh_iob/",
    GET_ONE: "api/wh_iob/",
    CREATE: "api/wh_iob/",
    UPDATE: "api/wh_iob/",
    DELETE: "api/wh_iob/",
    DELETE_BULK: "api/wh_iob/bulk-delete/",
    IMPORT: "api/wh_iob/import/",
    GET_HEADER: "api/wh_iob/headers/",
    SAVE_HEADER: "api/wh_iob/setup_headers/",
  },
  ACC_IOB: {
    GET_ALL: "api/acc_iob/",
    GET_ONE: "api/acc_iob/",
    CREATE: "api/acc_iob/",
    UPDATE: "api/acc_iob/",
    DELETE: "api/acc_iob/",
    DELETE_BULK: "api/acc_iob/bulk-delete/",
    IMPORT: "api/acc_iob/import/",
    GET_HEADER: "api/acc_iob/headers/",
    SAVE_HEADER: "api/acc_iob/setup_headers/",
  },
  PHYSICAL: {
    GET_ALL: "api/wh_physical_inventory/",
    GET_ONE: "api/wh_physical_inventory/",
    CREATE: "api/wh_physical_inventory/",
    UPDATE: "api/wh_physical_inventory/",
    DELETE: "api/wh_physical_inventory/",
    DELETE_BULK: "api/wh_physical_inventory/bulk-delete/",
    IMPORT: "api/wh_physical_inventory/import/",
    GET_HEADER: "api/wh_physical_inventory/headers/",
    SAVE_HEADER: "api/wh_physical_inventory/setup_headers/",
  },
  SELL: {
    GET_ALL: "api/acc_sell/",
    GET_ONE: "api/acc_sell/",
    CREATE: "api/acc_sell/",
    UPDATE: "api/acc_sell/",
    DELETE: "api/acc_sell/",
    DELETE_BULK: "api/acc_sell/bulk-delete/",
    IMPORT: "api/acc_sell/import/",
    GET_HEADER: "api/acc_sell/headers/",
    SAVE_HEADER: "api/acc_sell/setup_headers/",
  },
  ACC_PHYSICAL: {
    GET_ALL: "api/acc_physical_inventory/",
    GET_ONE: "api/acc_physical_inventory/",
    CREATE: "api/acc_physical_inventory/",
    UPDATE: "api/acc_physical_inventory/",
    DELETE: "api/acc_physical_inventory/",
    DELETE_BULK: "api/acc_physical_inventory/bulk-delete/",
    IMPORT: "api/acc_physical_inventory/import/",
    GET_HEADER: "api/acc_physical_inventory/headers/",
    SAVE_HEADER: "api/acc_physical_inventory/setup_headers/",
  },
  ACC_OTHER_OUTPUT: {
    GET_ALL: "api/acc_other_output/",
    GET_ONE: "api/acc_other_output/",
    CREATE: "api/acc_other_output/",
    UPDATE: "api/acc_other_output/",
    DELETE: "api/acc_other_output/",
    DELETE_BULK: "api/acc_other_output/bulk-delete/",
    IMPORT: "api/acc_other_output/import/",
    GET_HEADER: "api/acc_other_output/headers/",
    SAVE_HEADER: "api/acc_other_output/setup_headers/",
  },
  MAP_CODE: {
    GET_ALL: "api/map-code/",
    GET_ONE: "api/map-code/",
    CREATE: "api/map-code/",
    UPDATE: "api/map-code/",
    DELETE: "api/map-code/",
    DELETE_BULK: "api/map-code/bulk-delete/",
    IMPORT: "api/map-code/import/",
    GET_HEADER: "api/map-code/headers/",
    SAVE_HEADER: "api/map-code/setup_headers/",
  },
  TAX_RISK: {
    GET_ALL: "api/tax-risk/",
    GET_ONE: "api/tax-risk/",
    CREATE: "api/tax-risk/",
    UPDATE: "api/tax-risk/",
    DELETE: "api/tax-risk/",
    DELETE_BULK: "api/tax-risk/bulk-delete/",
    IMPORT: "api/tax-risk/import/",
    GET_HEADER: "api/tax-risk/headers/",
    SAVE_HEADER: "api/tax-risk/setup_headers/",
  },
  DESTROY: {
    GET_ALL: "api/destroy/",
    GET_ONE: "api/destroy/",
    CREATE: "api/destroy/",
    UPDATE: "api/destroy/",
    DELETE: "api/destroy/",
    DELETE_BULK: "api/destroy/bulk-delete/",
    IMPORT: "api/destroy/import/",
    GET_HEADER: "api/destroy/headers/",
    SAVE_HEADER: "api/destroy/setup_headers/",
  },
  BALANCE: {
    GET_ALL: "api/history/balance/",
    GET_ONE: "api/history/balance/",
    CREATE_STATEMENT_15: "api/logic/statement/15/",
    CREATE_STATEMENT_15A: "api/logic/statement/15A/",
    CREATE_BALANCE: "api/logic/balance_statement/",
    CREATE_CHECKING_STATEMENT_15: "api/logic/checking-statement/15/",
    CREATE_CHECKING_STATEMENT_15A: "api/logic/checking-statement/15A/",
    DELETE_BULK: "api/history/balance/bulk-delete/",
  },
};
