import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useMutation } from "react-query";

export const importFormA = (body) => {
  return jwtAxios.post(URL_API.CO_FORM.IMPORT, body);
};

const useImportFormA = () => {
  return useMutation({
    mutationFn: importFormA,
    onSuccess: () => {},
    onError: (err) => {
      message.error(err?.detail?.toString());
    },
  });
};

export default useImportFormA;
