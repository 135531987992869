import { AppRowContainer } from "@uni";
import AppCard from "@uni/core/AppCard";
import AppPageMetadata from "@uni/core/AppPageMetadata";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import IntlMessages from "@uni/utility/IntlMessages";
import { Card, Col, Tabs } from "antd";
import { lazy, useState } from "react";
import { useIntl } from "react-intl";
import "./index.style.less";
import {
  useCompanySettingConfig,
  useProfileSettingConfig,
  useSecuritySettingConfig,
} from "./useSettingConfig";

const FormSettingUser = lazy(() => import("./SettingCard/FormSettingUser"));
const FormSettingCompany = lazy(() =>
  import("./SettingCard/FormSettingCompany")
);
const FormSettingSecurity = lazy(() =>
  import("./SettingCard/FormSettingSecurity")
);

const TAB_OPTIONS = {
  PROFILE: "1",
  COMPANY: "2",
  SECURITY: "3",
};

export const centerItems = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 0,
  marginBottom: 0,
};

const Settings = () => {
  const { messages } = useIntl();
  const { TabPane } = Tabs;

  const profileConfig = useProfileSettingConfig();
  const companyConfig = useCompanySettingConfig();
  const securityConfig = useSecuritySettingConfig();

  const { companyManagement } = useLayoutContext();

  const [tab, setTab] = useState(TAB_OPTIONS.PROFILE);

  const handleChange = (tab) => {
    setTab(tab);
  };

  return (
    <>
      <AppPageMetadata title={messages["sidebar.setting.account-profile"]} />
      <AppRowContainer delay={150} style={{ height: "100%" }}>
        <Col span={24}>
          <Card className="card-setting">
            <Tabs
              defaultActiveKey={TAB_OPTIONS.PROFILE}
              onChange={handleChange}
            >
              <TabPane
                tab={<IntlMessages id="app.setting.profile.title" />}
                key={TAB_OPTIONS.PROFILE}
              >
                {
                  <AppCard
                    className={"app-card-setting"}
                    description={messages["app.setting.profile.description"]}
                  >
                    {" "}
                    <FormSettingUser
                      formField={profileConfig}
                      labelBtn={messages["app.setting.profile.btn"]}
                    />
                  </AppCard>
                }
              </TabPane>
              <TabPane
                tab={<IntlMessages id="app.setting.account-company.title" />}
                key={TAB_OPTIONS.COMPANY}
              >
                {
                  <AppCard
                    className={"app-card-setting"}
                    description={
                      messages["app.setting.account-company.description"]
                    }
                  >
                    {" "}
                    <FormSettingCompany
                      formField={companyConfig}
                      labelBtn={messages["app.setting.profile.btn"]}
                      companyManagement={companyManagement?.company}
                    />
                  </AppCard>
                }
              </TabPane>
              <TabPane
                tab={<IntlMessages id="app.setting.security.title" />}
                key={TAB_OPTIONS.SECURITY}
              >
                {
                  <AppCard
                    className={"app-card-setting"}
                    description={messages["app.setting.security.description"]}
                  >
                    {" "}
                    <FormSettingSecurity
                      formField={securityConfig}
                      labelBtn={messages["app.setting.security.description"]}
                    />
                  </AppCard>
                }
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </AppRowContainer>
    </>
  );
};

export default Settings;
