import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getInvoiceByInvoiceNo = async (params) => {
  const res = await jwtAxios.get(URL_API.ECUS.GET_ALL_INVOICE, { params });
  return res;
};

const useGetInvoiceByInvoiceNo = (params) =>
  useQuery(
    [URL_API.ECUS.GET_ONE_INVOICE, params],
    () => getInvoiceByInvoiceNo(params),
    {
      enabled:
        !!params.invoice_no && !!params.folder_slug && !!params.company_slug,
    }
  );

export default useGetInvoiceByInvoiceNo;
