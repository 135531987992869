import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";
import { Button, Tabs, Typography } from "antd";
import EditTable from "./EditTable";
import useAppConfirmModal from "hooks/useAppConfirmModal";
import { useCallback } from "react";
import IntlMessages from "@uni/utility/IntlMessages";

const StepFour = () => {
  const { contextHolder: contextConfirmModify, openModal: openConfirmModify } =
    useAppConfirmModal();
  const { contextHolder: contextConfirmBack, openModal: openConfirmBack } =
    useAppConfirmModal();
  const { modifyData, prevStep, setResult } = useGenerateContext();

  const handleConfirmModify = useCallback(() => {
    openConfirmModify({
      title: <IntlMessages id="bangke.modifyConfirm" />,
      okText: <IntlMessages id="common.modify" />,
      cancelText: <IntlMessages id="common.cancel" />,
      onOk: () => {
        setResult({ data: modifyData });
        prevStep();
      },
    });
  }, [modifyData, openConfirmModify, prevStep, setResult]);

  const handleConfirmBack = useCallback(() => {
    openConfirmBack({
      title: <IntlMessages id="bangke.backConfirm" />,
      okText: <IntlMessages id="common.confirm" />,
      cancelText: <IntlMessages id="common.cancel" />,
      onOk: () => {
        prevStep();
      },
    });
  }, [openConfirmBack, prevStep]);
  return (
    <>
      <Tabs>
        {modifyData?.map((item, index) => (
          <Tabs.TabPane tab={item?.ecus_input?.ecus_code} key={index}>
            <EditTable data={item} />
          </Tabs.TabPane>
        ))}
      </Tabs>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "8px",
          height: "100%",
          alignItems: "end",
        }}
      >
        <Button type="text" onClick={handleConfirmBack}>
          <IntlMessages id="common.cancel" />
        </Button>
        <Button type="primary" onClick={handleConfirmModify}>
          <IntlMessages id="common.modify" />
        </Button>
      </div>
      {contextConfirmModify}
      {contextConfirmBack}
    </>
  );
};

export default StepFour;
