import {
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const AppCustomDrawerWrapper = forwardRef(({ children }, ref) => {
  const [isFirstOpenModal, setIsFirstOpenModal] = useState(false);
  const childrenModalRef = useRef();
  const openDrawer = useCallback(() => {
    if (!isFirstOpenModal) setIsFirstOpenModal(true);
    else childrenModalRef.current?.openDrawer();
  }, [isFirstOpenModal]);
  useImperativeHandle(
    ref,
    () => ({
      openDrawer,
      closeModal: childrenModalRef.current?.closeModal,
    }),
    [openDrawer]
  );
  useEffect(() => {
    if (isFirstOpenModal) childrenModalRef.current?.openDrawer();
  }, [isFirstOpenModal]);
  return (
    isFirstOpenModal &&
    cloneElement(children, { ...children.props, ref: childrenModalRef })
  );
});

export default AppCustomDrawerWrapper;
