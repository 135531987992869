import React, { useMemo } from "react";
import { filterPermissionGrantedList } from "../../utility/Utils";
import { useAuthUser } from "../../utility/AuthHooks";
import { Space } from "antd";

const AppColumnActionOption = ({ items }) => {
  const { user } = useAuthUser();
  const menuItems = useMemo(() => {
    const filteredGrantedItems = filterPermissionGrantedList({
      listItems: items,
      permissions: user?.permissionRoles?.permission,
    });
    return filteredGrantedItems.map((item) => item.label);
  }, [items, user]);
  return <Space size={12}>{menuItems}</Space>;
};

export default AppColumnActionOption;
