export const API_FAILED = "Something Went Wrong!";

export const LABEL_INPUT = {
  NAME: "Name",
  PHONE_NUMBER: "Phone Number",
  BIRTHDAY: "Birthday",
  POSITION: "Position",
  ADDRESS: "Address",
  BIOGRAPHY: "Biography",
  NAME_COMPANY: "Company Name",
  TAX_CODE_COMPANY: "Tax Code",
  COUNTRY: "Country",
  EMAIL: "Email",
  CONTACT: "Contact",
  REPRESENTATIVE_NAME: "Representative Name",
  REPRESENTATIVE_EMAIL: "Representative Email",
  REPRESENTATIVE_CONTACT: "Representative Contact",
  PIC_NAME: "PIC Name",
  PIC_CONTACT: "PIC Contact",
  PASSWORD: "New password",
  PASSWORD_CONFIRM: "Re-enter New Password",
};

export const PLACEHOLDER_INPUT = {
  NAME: "Name",
  PHONE_NUMBER: "Phone Number",
  BIRTHDAY: "Birthday",
  POSITION: "Position",
  ADDRESS: "Address",
  BIOGRAPHY: "Biography",
  NAME_COMPANY: "Company Name",
  TAX_CODE_COMPANY: "Tax Code",
  COUNTRY: "Country",
  EMAIL: "Email",
  CONTACT: "Contact",
  REPRESENTATIVE_NAME: "Representative Name",
  REPRESENTATIVE_EMAIL: "Representative Email",
  REPRESENTATIVE_CONTACT: "Representative Contact",
  PIC_NAME: "PIC Name",
  PIC_CONTACT: "PIC Contact",
  PASSWORD: "New password",
  PASSWORD_CONFIRM: "Re-enter New Password",
  COMPANY: "Company",
};

export const REQUIRES_INPUT = {
  NAME: "Name is required",
  TAX_CODE_COMPANY: "Tax Code is required",
  COUNTRY: "Country is required",
  PASSWORD: "Password is required",
  PASSWORD_CONFIRM: "Confirm password is required",
};

export const RULES_INPUT = {
  PASSWORD_CONFIRM: "Passwords do not match",
};
