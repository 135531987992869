import AppViewDetail from "@uni/core/AppViewDetail";
import { downloadExcelStatement15 } from "@uni/utility/BalanceExport";
import useGetStatementById from "hooks/apis/makingStatement/useGetStatement15ById";
import { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  COL_WIDTHS_MAKING_STATEMENT15,
  FORMAT_CELLS_MAKING_STATEMENT15,
  MERGE_CELLS_MAKING_STATEMENT15,
} from "shared/constants/BalanceCons";

const ViewStatement15Detail = forwardRef(({ id, setDetailId }, ref) => {
  const { statement } = useGetStatementById(id);
  const [dataTable, setDataTable] = useState({});
  const { messages } = useIntl();

  useEffect(() => {
    if (statement) {
      const convertDataToTable = statement?.data?.map((item, index) => [
        index + 1,
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.begin_quantity || "",
        item?.import_quantity || "",
        item?.B13 || "",
        item?.A42 || "",
        item?.export_quantity || "",
        item?.other_export_quantity || "",
        item?.end_quantity || "",
      ]);

      const data = [
        [
          messages["data.exim.statement15.no"],
          messages["data.exim.statement15.code"],
          messages["data.exim.statement15.name"],
          messages["data.exim.statement15.unit"],
          messages["data.exim.statement15.beginQuantity"],
          messages["data.exim.statement15.importQuantity"],
          messages["data.exim.statement15.export1"],
          "",
          "",
          "",
          messages["data.exim.statement15.endQuantity"],
          messages["common.notes"],
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          messages["data.exim.statement15.reExportQuantity"],
          messages["data.exim.statement15.rePurposeQuantity"],
          messages["data.exim.statement15.exportToProduceQuantity"],
          messages["data.exim.statement15.otherExportQuantity"],
          "",
          "",
        ],
        ...convertDataToTable,
      ];

      setDataTable({
        data,
        mergeCells: MERGE_CELLS_MAKING_STATEMENT15,
        formatCell: FORMAT_CELLS_MAKING_STATEMENT15,
        colWidths: COL_WIDTHS_MAKING_STATEMENT15,
      });
    }
  }, [statement, messages]);

  return (
    <AppViewDetail
      setDetailId={setDetailId}
      dataTable={dataTable}
      downloadExcel={
        statement?.data &&
        (() =>
          downloadExcelStatement15(statement?.data, "STATEMENT15", messages))
      }
    />
  );
});

export default ViewStatement15Detail;
