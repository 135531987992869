/* eslint-disable no-unused-vars */
import { useMemo } from "react";
import { Button, Image, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ImageLoadFail from "../../../assets/images/ImageLoadFail.png";
import "./index.style.less";
import { AiOutlineCloseCircle } from "react-icons/ai";

const AppSingleImage = ({ value, onChange }) => {
  const uploadProps = useMemo(
    () => ({
      listType: "picture",
      accept: "image/*",
      showUploadList: false,
      beforeUpload: (file) => {
        const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/webp";
        if (!isJpgOrPng) {
          message.error("Bạn chỉ có thể upload ảnh JPG/PNG/WEBP!");
          return isJpgOrPng || Upload.LIST_IGNORE;
        }
        return false;
      },
      onChange: ({ file }) => {
        onChange(file);
      },
      customRequest: ({ onSuccess }) => {
        setTimeout(() => {
          onSuccess?.("ok");
        }, 0);
      },
    }),
    [onChange]
  );
  return (
    <Upload {...uploadProps}>
      {value ? (
        <div onClick={(e) => e.stopPropagation()}>
          <span className="container-avatar">
            <Image
              className={"avatar imageCustom"}
              src={
                typeof value === "string"
                  ? value
                  : value.url
                  ? value.url
                  : URL.createObjectURL(value)
              }
              preview={false}
              fallback={ImageLoadFail}
            />
            <AiOutlineCloseCircle
              className="close"
              onClick={() => {
                onChange(null);
              }}
            />
          </span>
        </div>
      ) : (
        <Button className={"avatar imageCustom"} icon={<UploadOutlined />}>
          Upload
        </Button>
      )}
    </Upload>
  );
};
export default AppSingleImage;
